<template>
  <v-app
    :style="{ 'background-color': $vuetify.theme.dark ? $vuetify.theme.themes.dark.bg : $vuetify.theme.themes.light.bg }">

    <!-- Format aspect-ratio="4 / 3" -->
    <!-- config para painel fixo <v-responsive content-class="c-app__layout__Sistema_ac_turbo_diesel" aspect-ratio="auto" max-height="100vh"> </v-responsive> -->

    <v-navigation-drawer app :mini-variant.sync="mini" expand-on-hover
      v-if="statusLogin.isAutenticacao && statusLogin.autenticado" v-model="drawer" width="150" class="ma-0 pa-0">
      <!-- LOGO ESQUERDA -->
      <v-list>
        <v-list-item class="d-inline-block ml-n2" to="/">
          <v-list-item-avatar class="ma-0 pa-0">
            <img src="@/assets/favicon-ds.png">
          </v-list-item-avatar>
          <!-- <v-btn icon @click.stop="drawer = !drawer">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn> -->
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <!-- Menu Vertical -->
      <v-list nav dense>
        <v-list-item-group v-model="menuSelecionado" color="primary">

          <!-- Menu Cliente -->
          <v-list-item class="mb-4" to="/Dashboard/ListarClientes">
            <v-list-item-icon class="mr-0 mt-1">
              <v-btn v-show="mini" fab small class="ml-n2 mt-n1">
                <v-icon color="primary">mdi-account-group</v-icon>
              </v-btn>
              <v-icon v-show="!mini">mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="ma-0 pa-0">CLIENTES</v-list-item-title>
          </v-list-item>

          <!-- Menu Produto -->
          <v-list-item to="/Dashboard/ListarProdutos" class="mb-4">
            <v-list-item-icon class="mr-0 mt-1">
              <v-btn v-show="mini" fab small class="ml-n2 mt-n1">
                <v-icon color="primary">mdi-package-variant-closed</v-icon>
              </v-btn>
              <v-icon v-show="!mini">mdi-package-variant-closed</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="ma-0 pa-0">PRODUTOS</v-list-item-title>
          </v-list-item>

          <!-- Menu Pedido -->
          <v-list-item to="/Dashboard/Pedidos">
            <v-list-item-icon class="mr-0 mt-1">
              <v-btn v-show="mini" fab small class="ml-n2 mt-n1">
                <v-icon color="primary">list_alt</v-icon>
              </v-btn>
              <v-icon v-show="!mini">list_alt</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="ma-0 pa-0">PEDIDOS</v-list-item-title>
          </v-list-item>

        </v-list-item-group>
      </v-list>
      <!-- Usuario -->
      <div class="d-sm-none mt-4" :class="{'pl-10': !mini, 'mt-n1': !mini}">
        <produtosPregao />
      </div>
    </v-navigation-drawer>

    <!-- Navegação  horizontal-->
    <v-progress-linear top fixed :active="progresso" color="primary" indeterminate height="3" class="u-z-index--99" />
    <v-app-bar app v-if="statusLogin.isAutenticacao && statusLogin.autenticado">
      
      <!-- Alternador -->
      <v-app-bar-nav-icon @click="drawer = !drawer">
        <v-icon v-show="drawer">menu_open</v-icon>
        <v-icon v-show="!drawer" class="u-rotacao">menu_open</v-icon>
      </v-app-bar-nav-icon>

      <!-- Nome Fantasia -->
      <v-toolbar-title class="ma-0 pa-0">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text dark v-bind="attrs" v-on="on">
              <h3 class="text-white--text"> {{ empresasNoBd[empresaSelecionada].nomeFantasia }}</h3>
              <v-icon>expand_more</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item-group v-model="empresaSelecionada" color="primary">
              <v-list-item v-for="(item, index) in empresasNoBd" :key="index">
                <v-list-item-avatar>
                  <v-img :src="item.logo.url"></v-img>
                </v-list-item-avatar>
                <v-list-item-title class="text-left">{{ item.nomeFantasia }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>

          <!-- Menu horizontal empresa -->
          <v-list>
            <v-list-item-group color="primary">
              <v-list-item to="/Dashboard/Empresa">
                <v-list-item-icon>
                  <v-icon>mdi-folder-wrench</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="text-left">CONFIGURAÇÕES</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>

        </v-menu>
      </v-toolbar-title>
      <v-row dense no-gutters align="center" align-content="center" class="c-menu-horizontal">
        <v-col cols="12" sm="2">
          <v-btn to="/Dashboard/ListarProdutos" class="primary--text">
            <v-icon left color="primary">mdi-package-variant-closed</v-icon> PRODUTOS
          </v-btn>
        </v-col>
        <v-col cols="12" sm="2">
          <v-btn to="/Dashboard/Categorias" class="primary--text">
            <v-icon left color="primary">category</v-icon> CATEGORIAS
          </v-btn>
        </v-col>
        <v-col cols="12" sm="2">
          <v-btn to="/Dashboard/TabelaDePreco" class="primary--text">
            <v-icon left color="primary">local_atm</v-icon> TABELAS DE PREÇO
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="1" class="d-none d-sm-block">
          <v-switch dense v-model="$vuetify.theme.dark" hide-details prepend-icon="mdi-theme-light-dark"></v-switch>
        </v-col>

        <!-- Menu usuário -->
        <v-col cols="12" sm="1" class="ma-0 pa-0 d-none d-sm-block">
          <produtosPregao />
        </v-col>
      </v-row>
    </v-app-bar>

    <!-- Corpo do sistema -->
    <v-main id="c-main">
      <v-container v-if="!statusLogin.isAutenticacao && !statusLogin.autenticado" class="fill-height u-p-fixed" fluid>
        <router-view />
      </v-container>
      <v-container v-if="statusLogin.isAutenticacao && statusLogin.autenticado" fluid>
        <v-row class="ma-a pa-0">
          <v-col cols="12" sm="12" class="ma-a pa-0">
            <router-view name="Dashboard"></router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

  </v-app>
</template>

<script>
urlApiBd
import { urlApiBd } from "@/global";
import produtosPregao from "@/views/usuario/Usuario.vue";
import axios from "axios";
import barramento from "@/barramento"
export default {
  components: { produtosPregao },
  data: () => ({
    progresso: false,
    menuSelecionado: 0,
    mini: true,
    drawer: null,
    statusLogin: false,
    dataUser: {},
    empresaSelecionada: 0,
    empresasNoBd: [{
      nomeFantasia: '',
      logo: { url: '' }
    },
    {
      nomeFantasia: '',
      logo: { url: '' }
    },
    ]
  }),
  watch: {
    $route: {
      handler(novo) {
        this.statusLogin = novo.meta
      },
      immediate: true,
    },
  },
  methods: {
    listarEmpresas() {
      console.log('opa logou aqui')
      axios.post(`${urlApiBd}/get-empresas`).then(res => {
        if (res.data.result.length == 0) {
          this.empresasNoBd = [{
            nomeFantasia: 'DS',
            logo: { url: require('../src/assets/favicon-ds.png') }
          },
          {
            nomeFantasia: 'PROCIS',
            logo: { url: require('../src/assets/logoprocis.png') }
          },
          ]
        }else{
          console.log('this.empresasNoBd', this.empresasNoBd)
          this.empresasNoBd = res.data.result
        }
        
        /* this.$toasted.global.defaultSuccess('Empresas encontradas!'); */
      }).catch(err => {
        this.$toasted.global.defaultError(err.response.data.message);
      })
    },
  },
  created() {
    /* this.listarEmpresas() */
    barramento.getUserSuspeito((e) => {
      if (e) this.$router.push('/Login')
    });
    barramento.getProgresso(e => this.progresso = e);
  },
  /*  beforeRouteUpdate(to, from, next) {
         console.log('to', to, 'from', from)
         next()
     } */
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#c-main .v-main__wrap {
  display: flex;
  justify-content: center;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

/* Class gerais */

.u-white-space-normal {
  white-space: normal !important;
  word-break: break-word;
}
.u-disabled * {
  cursor: not-allowed !important;
}
.u-rotacao {
  transform: rotateZ(180deg);
}

.u-transicao {
  transition: all 1s ease 0s !important;
}
.u-cursor {
  cursor: pointer;
}

.u-events-none {
  pointer-events: none!important;
}
.u-text-initial {
  text-transform: initial !important;
}

.u-p-fixed {
  position: fixed;
}

.u-p-absolute {
  position: absolute;
}

.u-p-relative {
  position: relative;
}

.u-z-index--10 {
  z-index: 10;
}

.u-z-index--99 {
  z-index: 99;
}

// Animação
// Animação loading em botões
.u-loading-btn {
  animation: loading-btn 1s ease 0s infinite normal none;
}

@keyframes loading-btn {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
