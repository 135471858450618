<template>
    <v-row class="ma-0 pa-0">
        <!-- Formúlario Cliente -->
        <v-col v-if="'id' in $route.params" cols="12" class="ma-0 pa-0">
            <v-slide-y-transition leave-absolute>
                <router-view></router-view>
            </v-slide-y-transition>
        </v-col>
        <!-- Lista de Clientes -->
        <v-col v-else cols="12" class="ma-0 pa-0">
            <v-slide-y-transition hide-on-leave appear>
                <v-data-table :headers="headers" :items="produtos" :search="pesquisaCliente" sort-by="nomeParaTabela"
                    class="ma-0 pa-0">
                    <template v-slot:top>
                        <v-toolbar flat>

                            <v-btn fab small class="mr-4" to="/Dashboard/ListarProdutos/Produto/add">
                                <v-icon color="primary">mdi-package-variant-closed-plus</v-icon>
                            </v-btn>

                            <v-divider class="mr-4" inset vertical />
                            <v-switch dense v-model="modoEdicao" hide-details label="MODO EDIÇÃO"></v-switch>
                            <v-spacer />

                            <v-text-field dense outlined v-model="pesquisaCliente" append-icon="mdi-magnify" label="Pesquisar Produto"
                                single-line hide-details />

                        </v-toolbar>

                    </template>

                    <!-- Img produto -->
                    <template v-slot:[`item.imagens`]="{ value }">
                        <v-icon v-if="Object.keys(value).length == 0" x-large>mdi-image-remove</v-icon>
                        <v-img v-else :src="value[0].foto_thumb" max-width="50"></v-img>
                    </template>

                    <!-- Valor unitário -->
                    <template v-slot:[`item.valor_produto`]="{ value }">
                        <v-text-field prefix="R$" flat disabled :value="value | dinheiro">
                        </v-text-field>
                    </template>

                    <!-- Ações -->
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-btn icon large :to="`/Dashboard/ListarProdutos/Produto2/${item.id_produto}`">
                            <v-icon large>
                                mdi-storefront-edit-outline
                            </v-icon>
                        </v-btn>
                        <v-btn v-if="modoEdicao" class="ml-6" icon large disabled>
                            <v-icon large>
                                delete_forever
                            </v-icon>
                        </v-btn>
                        <!--  <v-icon small>
                            mdi-delete
                        </v-icon> -->
                    </template>

                    <!-- Quando não houver dados na tabela -->

                    <template v-slot:no-data>
                        <v-btn @click="listarProdutos">
                            BUSCAR PRODUTOS
                        </v-btn>
                    </template>
                </v-data-table>
            </v-slide-y-transition>
        </v-col>
    </v-row>
</template>
    
<script>
/* import { cofingHeadersVhsys } from "@/global";  */
import barramento from "@/barramento"
import axios from "axios";
export default {
    name: "ListaProdutos",
    data: () => ({
        modoEdicao: false,
        pesquisaCliente: '',
        headers: [
            { text: 'ID', align: 'start', value: 'id_produto', class: 'd-none c-y-center', cellClass: 'd-none' },
            { text: 'N°', align: 'start', value: 'id_registro', class: 'c-y-center' },
            { text: 'Fotos', align: 'start', value: "imagens", class: 'c-y-center' },
            { text: 'Nome', align: 'start', value: 'desc_produto', class: 'c-y-center' },
            { text: 'Código', value: 'cod_produto', class: 'c-y-center' },
            { text: 'Estoque', value: 'estoque_produto' , class: 'c-y-center' },
            { text: 'Valor para loja', value: 'valor_produto', class: 'c-y-center' },
            { text: 'Ações', value: 'actions', sortable: false, class: 'c-y-center' },
        ],
        produtos: [],
    }),

    computed: {},

    watch: {},

    methods: {
        listarProdutos() {
            
            barramento.enviarProgresso(true)
            axios
                .get('/produtos?loja_visivel=1', {
                    headers: JSON.parse(sessionStorage.getItem('keyVhsys__aviz__user'))
                }).then(res => {
                    console.log('API E-commerce DS', res.data.data)
                    this.produtos = res.data.data
                    barramento.enviarProgresso(false)

                }).catch(res => {
                    barramento.enviarProgresso(false)
                    this.$toasted.global.defaultError(res.response.data.message);
                })
        },
    },
    created() {
        this.listarProdutos()
    },
    beforeRouteUpdate(to, from, next) {
        if ('id' in from.params) {
            this.listarProdutos()
        }
        /*  console.log('to', to, 'from', from) */
        next()
    }
};
</script>
    
<style>
.c-y-center {
    width: 80px!important;
}
</style>