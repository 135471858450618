import Vue from 'vue'
import Toasted from 'vue-toasted'

Vue.use(Toasted,{
    theme: "bubble",
    iconPack: 'md',
    duration: 2500,
    className: 'alerta',
    position: 'bottom-right'
})

Vue.toasted.register(
    'defaultSuccess',
    payload => !payload ? 'Operação realizada com sucesso!': payload,
    { type: 'success', icon: 'check_circle'}
)

Vue.toasted.register(
    'defaultError',
    payload => !payload ? 'Operação... Erro Inesperado!': payload,
    { type: 'error', icon: 'report_problem'}
)