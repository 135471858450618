<template>
    <v-row class="px-md-11">
        <v-col cols="12">
            <v-card color="secondary">
                <v-card-title>
                    <h3 class="text-h6 text-uppercase font-weight-bold">Novo produto ou serviço</h3><v-btn v-if="loading"
                        color="error" class="mx-3 black--text font-weight-bold mt-1 mt-sm-0" x-small
                        @click="loading = !loading">CANCELAR CONSULTA</v-btn>
                    <v-divider class="ml-2"></v-divider>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <!-- Dados do Produto -->
                        <v-col cols="12" sm="12" md="12" class="ma-0 pa-0 mt-n1 mb-1 text-ringht d-flex justify-end">
                            <v-btn dense class="mr-3 mt-n2" right>FORMADOR DE PREÇO <v-icon
                                    right>request_quote</v-icon></v-btn>
                        </v-col>
                        <v-col cols="12" sm="12" md="7" class="py-0 pt-5 py-md-3">
                            <v-text-field label="Nome" placeholder="Digite o nome do produto" outlined
                                v-model="dadosProduto.desc_produto" :disabled="loading" :loading="loading"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" class="py-0 pt-5 py-md-3">
                            <v-text-field label="Código do produto" placeholder="Digite o código do produto" outlined
                                v-model="dadosProduto.cod_produto" :disabled="loading"
                                :loading="loading"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="2" class="py-0 pt-5 py-md-3">
                            <v-select v-model="dadosProduto.tipo" :items="opTipo" label="Tipo" outlined :disabled="loading"
                                :loading="loading">
                            </v-select>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" class="py-0 pt-5 py-md-3">
                            <v-currency-field prefix="R$" label="Valor de custo" placeholder="Digite o valor do custo"
                                outlined v-model="dadosProduto.valorDeCusto" :disabled="loading"
                                :loading="loading"></v-currency-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" class="py-0 pt-5 py-md-3">
                            <v-currency-field prefix="R$" label="Valor de venda" placeholder="Digite o valor de venda"
                                outlined v-model="dadosProduto.valor_produto" :disabled="loading"
                                :loading="loading"></v-currency-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" class="py-0 pt-5 py-md-3">
                            <v-text-field label="Marca" placeholder="Digite a marca" outlined v-model="dadosProduto.marca"
                                :disabled="loading" :loading="loading"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" class="py-0 pt-5 py-md-3">
                            <v-text-field label="Fornecedor" placeholder="Digite o fornecedor" outlined
                                v-model="dadosProduto.fornecedor" :disabled="loading" :loading="loading"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="2" class="py-0">
                            <span>Peso - em Kg</span>
                            <v-text-field label="Bruto" placeholder="Digite o bruto" outlined
                                v-model="dadosProduto.pesoEmKgBruto" :disabled="loading" :loading="loading">
                            </v-text-field>
                            <v-text-field label="Líquido" placeholder="Digite o líquido" outlined
                                v-model="dadosProduto.pesoEmKgLiquido" :disabled="loading" :loading="loading">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" class="py-0 pt-5">
                            <v-text-field label="Tamanho do produto" placeholder="Digite o tamanho" outlined
                                v-model="dadosProduto.tamanhoDoProduto" :disabled="loading" :loading="loading">
                            </v-text-field>
                            <v-text-field label="Unidade" placeholder="Digite a unidade" outlined
                                v-model="dadosProduto.unidade" :disabled="loading" :loading="loading" hint="Ex.: Pç, Kg, Un"
                                persistent-hint>
                            </v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="4" class="py-0 pt-5">
                            <v-select v-model="dadosProduto.tipoDeClassificacao" :items="opTipoClassificacao"
                                label="Tipo de classificação" outlined :disabled="loading" :loading="loading" clearable>
                            </v-select>
                            <v-select v-model="dadosProduto.origem" :items="opTipoOrigem" label="Origem do produto" outlined
                                :disabled="loading" :loading="loading" clearable>
                            </v-select>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" class="py-0 pt-5">
                            <v-text-field label="Código GTIN/EAN" placeholder="Digite o GTIN/EAN" outlined
                                v-model="dadosProduto.codigoGtinEan" :disabled="loading" :loading="loading">
                            </v-text-field>
                            <v-text-field label="NCM" placeholder="Digite o NCM" outlined v-model="dadosProduto.ncm"
                                :disabled="loading" :loading="loading" class="c-text-ncm">
                                <template v-slot:append-outer>
                                    <span class="primary-text text-caption"> <a
                                            href="http://www4.receita.fazenda.gov.br/simulador/" target="_blank"
                                            class="text-decoration-none">VERIFICAR
                                            NCM</a></span>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" class="py-0 pt-5">
                            <v-text-field prepend-inner-icon="mdi-barcode-scan" label="Código de barras interno"
                                placeholder="Digite o código de barras" outlined
                                v-model="dadosProduto.codigoDeBarrasInterno" :disabled="loading" :loading="loading">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="5" class="pt-5 ma-0 pa-0 text-left">
                            <v-autocomplete v-model="dadosProduto.categoriaProduto" :items="opCategoriaProduto" outlined
                                chips small-chips label="Categoria do produto" multiple></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" class="py-0 pt-5 text-right">
                            <!--                             <v-select v-model="dadosProduto.situacao" :items="opSituacao" label="Situação" outlined
                                :disabled="loading" :loading="loading">
                            </v-select> -->
                            <v-switch v-model="dadosProduto.situacao"
                                :label="`Situação - ${dadosProduto.situacao ? 'Ativo' : 'Inativo'}`" hide-details
                                class="pl-3">
                            </v-switch>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" class="my-0 py-0 text-sm-left">
                            <span class="text-caption primary--text text-uppercase d-flex align-center">Dados para o
                                Estoque<v-divider class="ml-2 linha-endereco" /></span>
                            <v-row class="mt-0">
                                <v-col cols="4">
                                    <v-text-field prepend-inner-icon="mdi-chart-timeline" label="Localização no estoque"
                                        placeholder="Digite a localização" outlined v-model="dadosProduto.localNoEstoque"
                                        :disabled="loading" :loading="loading" hint="Exe. EST 2 / PRAT C" persistent-hint>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field label="Estoque atual" placeholder="0" :value="0" outlined disabled
                                        class="c-estoque-atual" hint="Calc. Automático" persistent-hint>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-text-field hide-spin-buttons label="Estoque mínimo" type="number" placeholder="Digite o estoque mínimo"
                                        outlined v-model.number="dadosProduto.estoqueMinimo" :disabled="loading"
                                        :loading="loading">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-text-field hide-spin-buttons type="number" label="Estoque máximo" placeholder="Digite o estoque máximo" outlined
                                        v-model.number="dadosProduto.estoqueMaximo" :disabled="loading" :loading="loading">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" class="py-0 pt-5">
                            <v-textarea filled label="Observações do produto" auto-grow
                                v-model="dadosProduto.observacoes"></v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12">
            <v-card color="secondary">
                <v-card-title>
                    <h3 class="text-h6 text-uppercase font-weight-bold">E-commerce</h3>
                    <v-divider class="ml-2"></v-divider>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <!--  CONFIGURAÇÕES LOJA VIRTUAL-->
                        <v-col cols="12" sm="12" md="3" class="py-0 pt-5 py-md-3">
                            <v-switch v-model="opcoesLoja.visivel">
                                <template v-slot:label>
                                    <span v-if="opcoesLoja.visivel">Produto visível <v-icon
                                            color="primary">mdi-storefront-check</v-icon></span>
                                    <span v-else>Produto visível <v-icon color="error">mdi-storefront-remove</v-icon></span>
                                </template>
                            </v-switch>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" class="py-0 pt-5 py-md-3">
                            <v-switch v-model="opcoesLoja.freteGratis">
                                <template v-slot:label>
                                    <span v-if="opcoesLoja.freteGratis">Frete grátis <v-icon
                                            color="primary">mdi-archive-star</v-icon>
                                    </span>
                                    <span v-else>Frete grátis <v-icon color="error">mdi-close-circle-multiple</v-icon>
                                    </span>
                                </template>
                            </v-switch>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" class="py-0 pt-5 py-md-3">
                            <v-switch v-model="opcoesLoja.transportadora">
                                <template v-slot:label>
                                    <span v-if="opcoesLoja.transportadora">Transportadora <v-icon
                                            color="primary">mdi-truck-check-outline</v-icon>
                                    </span>
                                    <span v-else>Entrega própria <v-icon color="error">mdi-car-pickup</v-icon>
                                    </span>
                                </template>
                            </v-switch>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" class="py-0 pt-5 py-md-3">
                            <v-switch v-model="opcoesLoja.produtoEmDestaque">
                                <template v-slot:label>
                                    <span v-if="opcoesLoja.produtoEmDestaque">Destaque na loja <v-icon
                                            color="primary">mdi-star-check</v-icon>
                                    </span>
                                    <span v-else>Destaque na loja <v-icon color="error">mdi-star-off</v-icon>
                                    </span>
                                </template>
                            </v-switch>
                        </v-col>

                        <v-col cols="12" sm="12" md="4" class="py-0 pt-5 py-md-3 mb-0">
                            <v-text-field type="number" label="Pédido mínimo" placeholder="Add pedido mínimo" outlined
                                v-model.number="opcoesLoja.pedidoMinimo" :disabled="loading" :loading="loading">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" class="py-0 pt-5 py-md-3 mb-0">
                            <v-select v-model="opcoesLoja.tabelaPreco" :items="opTabelaPreco" label="Tabela e-commerce"
                                outlined :disabled="loading" :loading="loading" hide-details clearable>
                            </v-select>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" class="py-0 pt-5 py-md-3 mb-0">
                            <v-currency-field label="Valor exclusivo" placeholder="Defina o valor exclusivo" outlined
                                v-model.number="dadosProduto.valor_produto" :disabled="loading" :loading="loading">
                            </v-currency-field>
                        </v-col>

                        <!-- Dimensões -->
                        <v-col cols="12" sm="12" md="12" class="my-0 py-0 text-sm-left">
                            <span class="text-caption primary--text text-uppercase d-flex align-center">Dimensões da
                                embalagem (Centímetros)<v-divider class="ml-2 linha-endereco" /></span>
                            <v-row class="mt-0">
                                <v-col cols="2">
                                    <v-text-field type="number" label="Altura" placeholder="Add altura" outlined
                                        v-model.number="opcoesLoja.atura" :disabled="loading" :loading="loading">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field type="number" label="Largura" placeholder="Add largura" outlined
                                        v-model.number="opcoesLoja.largura" :disabled="loading" :loading="loading">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field type="number" label="Comprimento" placeholder="Add comprimento" outlined
                                        v-model.number="opcoesLoja.comprimento" :disabled="loading" :loading="loading">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-file-input v-model="fotosDoProduto" label="Fotos" color="segunda" outlined
                                        prepend-icon="" prepend-inner-icon="wallpaper">
                                    </v-file-input>
                                </v-col>
                                <v-col v-if="'url' in fotosDoProdutoNoBanco" cols="3">
                                    <v-img max-width="250" class="text-center" :src="fotosDoProdutoNoBanco.url"></v-img>
                                </v-col>
                                <v-col v-else cols="3">
                                    <span class="c-img-produto-moldura">IMAGEM PRODUTO</span>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- Fiscais e tributárias -->
                        <v-col cols="12" sm="12" md="12" class="my-0 py-0 text-sm-left">
                            <span class="text-caption primary--text text-uppercase d-flex align-center">Fiscais e
                                tributárias<v-divider class="ml-2 linha-endereco" />
                            </span>
                            <v-row class="mt-0">
                                <v-col cols="3">
                                    <v-text-field v-maska data-maska="##,###" label="Alíquota de ICMS" placeholder="%"
                                        outlined v-model="fiscalEtributario.aliquotaIcms" :disabled="loading"
                                        :loading="loading" hide-details>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-text-field v-maska data-maska="##,###" label="Alíquota de IPI" placeholder="%"
                                        outlined v-model="fiscalEtributario.aliquotaIpi" :disabled="loading"
                                        :loading="loading" hide-details>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-text-field v-maska data-maska="##,###" label="Alíquota de PIS" placeholder="%"
                                        outlined v-model="fiscalEtributario.aliquotaPis" :disabled="loading"
                                        :loading="loading" hide-details>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-text-field v-maska data-maska="##,###" label="Alíquota de COFINS" placeholder="%"
                                        outlined v-model="fiscalEtributario.cofins" :disabled="loading" :loading="loading"
                                        hide-details>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-text-field label="Unidade Tributável" placeholder="Digite a unidade tributável"
                                        outlined v-model="fiscalEtributario.unidadeTributavel" :disabled="loading"
                                        :loading="loading" hint="Ex.: Pç, Kg, Un" persistent-hint>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-text-field label="Código benefício fiscal"
                                        placeholder="Digite o código benefício fiscal" outlined
                                        v-model="fiscalEtributario.codBeneficioFiscal" :disabled="loading"
                                        :loading="loading">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field label="Código CEST" placeholder="Digite o código CEST" outlined
                                        v-model="fiscalEtributario.codCest" :disabled="loading" :loading="loading">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field v-maska data-maska="##,####" label="Federal" placeholder="%" outlined
                                        v-model="fiscalEtributario.federal" :disabled="loading" :loading="loading">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field v-maska data-maska="##,####" label="Estadual" placeholder="%" outlined
                                        v-model="fiscalEtributario.estadual" :disabled="loading" :loading="loading">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" class="mt-n4">
                                    <v-radio-group disabled v-model="fiscalEtributario.paramentrosFiscaisNfe"
                                        class="ma-0 pa-0">
                                        <v-radio
                                            label="Parâmetros na Nota Fiscal - NF-e (Se esta opção for marcada estes parâmetros sobrepõem os parâmetros gerais)."
                                            value="SobreporPadraoNF-e"></v-radio>
                                    </v-radio-group>
                                    <v-radio-group disabled v-model="fiscalEtributario.paramentrosFiscaisNfce"
                                        class="ma-0 pa-0">
                                        <v-radio
                                            label="Parâmetros na Nota Fiscal de Consumidor - NFC-e (Se esta opção for marcada estes parâmetros sobrepõem os parâmetros gerais)."
                                            value="SobreporPadraoNFC-e"></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>

            </v-card>
        </v-col>
        <v-speed-dial fixed v-model="btnAcoes" :bottom="true" :right="true" transition="slide-y-reverse-transition"
            class="ma-0 pa-0">
            <template v-slot:activator>
                <v-btn v-model="btnAcoes" color="primary" dark fab class="mt-n3" :loading="loading" :disabled="loading">
                    <v-icon v-if="dadosProduto.objectId !== null" large color="bg">
                        cloud_sync
                    </v-icon>
                    <v-icon v-else large color="bg">
                        backup
                    </v-icon>
                </v-btn>
            </template>

            <!-- Botão salvar dados cliente ou atualizar -->
            <span v-if="dadosProduto.objectId !== null" class="c-dial-text primary--text">ATUALIZAR</span>
            <span v-else class="c-dial-text primary--text mt-n2">SALVAR</span>
            <v-btn fab @click="addProduto()">
                <v-icon v-if="dadosProduto.objectId !== null">mdi-reload</v-icon>
                <v-icon v-else>mdi-content-save-cog-outline</v-icon>
            </v-btn>

            <!-- Botão voltar -->
            <span class="c-dial-text primary--text mt-n2">VOLTAR</span>
            <v-btn fab to="/Dashboard/ListarProdutos">
                <v-icon class="u-rotacao">shortcut</v-icon>
            </v-btn>
        </v-speed-dial>
    </v-row>
</template>
  
<script>
import barramento from "@/barramento"
import axios from "axios";
import { urlApiFiles, UF } from "@/global";
const cofingHeadersVhsysFake = ''
export default {
    name: "Produto-screen",
    props: {},
    components: {},
    data: () => ({
        btnAcoes: true,
        fotosDoProduto: null,
        fotosDoProdutoNoBanco: {},
        loading: false,
        fiscalEtributario: {},
        opcoesLoja: {
            objectId: null,
        },
        dadosProduto: {
            objectId: null,
            situacao: false
        },
        opTipo: ['Produto', 'Serviço'],
        opSituacao: ['Ativo', 'Inativo'],
        opTipoClassificacao: ["Mercadoria para Revenda", "Matéria-Prima Embalagem", "Produto em Processo", "Produto Acabado", "Subproduto", "Produto Intermediário", "Material de Uso e Consumo", "Ativo Imobilizado", "Serviços", "Outros insumos", "Outras"]
        ,
        opTipoOrigem: ["Nacional", "Estrangeira - Importação direta", "Estrangeira - Adquirida no mercado interno", "Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40%", "Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam as legislações citadas nos Ajustes", "Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%", "Estrangeira - Importação direta, sem similar nacional, constante em lista da CAMEX", "Estrangeira - Adquirida no mercado interno, sem similar nacional, constante em lista da CAMEX", "Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70%;"],
        opCategoriaProduto: ["Válvulas de admissão", "Válvulas de escape", "Pistões", "Anéis de pistão", "Bielas", "Virabrequim", "Cabeçote", "Junta do cabeçote", "Válvula EGR", "Sensor de temperatura do motor", "Sensor de pressão do óleo", "Bomba de combustível", "Filtro de combustível", "Filtro de ar", "Sistema de arrefecimento", "Radiador", "Termostato", "Bomba de água", "Turbocompressor", "Sistema de escape", "Sistema de freios", "Pastilhas de freio", "Discos de freio", "Cilindro mestre do freio", "Cilindros de roda", "Sistema de suspensão", "Molas de suspensão", "Amortecedores", "Barra estabilizadora", "Caixa de direção"],
        opTabelaPreco: ['Tabela-e-commerce', 'Tabela-5%', 'Tabela+5%'],
        configuracoesCliente: {},
        opListaDepreco: [],
        opCondicaoDePagamento: [],
        opNomeVendedor: [],
        opNomeProfissional: [],
        opEstado: UF
    }),
    computed: {
        getLogo() {
            return data => {

                return data != undefined ? data.url : ''
            }
        }
    },
    watch: {
        fotosDoProduto(logoNova, logoAntiga) {
            if (logoNova == null && !('name' in this.fotosDoProdutoNoBanco)) return

            if (logoNova == null && 'name' in this.fotosDoProdutoNoBanco) {
                this.atualizarFotosProduto(this.fotosDoProdutoNoBanco.caminho, 'atualizarArquivo')
                return
            }
            if ('name' in logoNova && logoAntiga == null) {
                this.atualizarFotosProduto(logoNova, 'inserirArquivo')
            }
        },
    },
    methods: {
        atualizarFotosProduto(fotosDoProduto, acao) {
            let logoAdd = []

            logoAdd.push(fotosDoProduto)

            let formData = new FormData();
            logoAdd.filter((ele) => {
                formData.append('arquivo', ele);
                formData.append('acao', acao);
                formData.append('classBd', 'Produto');
                formData.append('contexto', 'Foto Produto');
            });
            axios
                .post(`${urlApiFiles}/UpArquivo`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    if (res.data.arquivoDeletado) {
                        this.fotosDoProdutoNoBanco = {}
                        this.$toasted.global.defaultSuccess(false);
                        return
                    }
                    this.fotosDoProdutoNoBanco = { ...res.data[0] };
                    this.$toasted.global.defaultSuccess('Dados da fota armazenados!');
                })
                .catch(err => {
                    this.$toasted.global.defaultError(err.response.data.message);
                });
        },
        carregarProduto(id) {
            if (id == 'add') return;

            barramento.enviarProgresso(true)

            axios
                .get(`/produtos/${id}`, {
                    headers: cofingHeadersVhsysFake
                }).then(res => {
                    console.log('res', res.data.data)
               /*  this.opcoesLoja = res.data.result.lojaVirtual
                delete res.data.result.lojaVirtual */
                this.dadosProduto = res.data.data
                this.$toasted.global.defaultSuccess('Dados do cliente carregado');
                barramento.enviarProgresso(false)

            }).catch(err => {
                this.$toasted.global.defaultError(err.response.data.message);
                barramento.enviarProgresso(false)
            })
        },
        addProduto() {
            this.loading = true

            const dataProduto = Object.assign({}, this.dadosProduto)
            dataProduto.fotos = this.fotosDoProdutoNoBanco
            dataProduto.opcoesLoja = Object.assign({}, this.opcoesLoja)

            axios
                .post('/cadastrar-produto', dataProduto)
                .then(res => {

                    this.dadosProduto.objectId = res.data.result.idProduto
                    this.opcoesLoja.objectId = res.data.result.idOpcoesLoja
                    this.loading = false
                    this.$toasted.global.defaultSuccess(false);
                }).catch(err => {
                    this.loading = false
                    this.$toasted.global.defaultError(err.response.data.error);
                })
        },
        parseCepECnpj(cep) {
            return cep.replace(/[^0-9]+|\s+/g, "");
        },
    },
    created() {
        if ('id' in this.$route.params) {
            this.carregarProduto(this.$route.params.id)
        }
    },
    beforeMount() { },
    mounted() { }
};
</script>
  
<style>

.c-img-produto-moldura {
    border: solid 1px;
    padding: 0px;
    width: 230px;
    height: 54px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.c-dial-text {
    display: block;
    height: 30px;
    padding-top: 9px;
    width: 56px;
    font-size: 10px;
    line-height: 10px;
    font-weight: 500;
}

.c-text-ncm .v-input__append-outer {
    position: absolute;
    bottom: 4px;
}
</style>