<!-- eslint-disable -->
<template>
  <v-card>
    <v-card-title>Tabela de Preço</v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="priceTable"
        :items-per-page="5"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar extended flat class="pt-7">
            <v-text-field hide-details outlined v-model="newTableName" label="Nome da Tabela"></v-text-field>
            <v-select hide-details outlined v-model="selectedType" :items="['Porcentagem', 'Valor']" label="Tipo" class="mx-10"></v-select>
            <v-spacer></v-spacer>
            <v-btn fab small @click="updateValue(-1)" color="bg2">-</v-btn>
            <v-text-field hide-spin-buttons type="number" hide-details dense outlined v-model.number="selectedValue" label="Valor" class="mx-5"></v-text-field>
            <v-btn fab small @click="updateValue(1)" color="bg2">+</v-btn>
            <v-btn outlined color="primary" @click="savePriceTable" class="mx-5">
              {{ editingIndex !== null ? 'Salvar Edição' : 'Salvar Tabela' }}
            </v-btn>
            <v-btn outlined @click="cancelEdit" v-if="editingIndex !== null">Cancelar</v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn @click="editPriceTable(item)" icon>
            <v-icon color="primary">mdi-pencil</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.value="{ item }">
          <template v-if="item.type === 'Porcentagem'">
            <template v-if="item.value > 0">+ {{ item.value }}%</template>
            <template v-else>{{ item.value }}%</template>
          </template>
          <template v-else>
            <template v-if="item.value > 0">+ {{ item.value | dinheiro }}</template>
            <template v-else>{{ item.value | dinheiro }}</template>
          </template>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios';
const urlApi = 'sua_url_api'; // Substitua pela sua URL de API
const configK = {}; // Substitua pelo cabeçalho necessário

export default {
  data() {
    return {
      headers: [
        { text: 'Nome da Tabela', value: 'name' },
        { text: 'Tipo', value: 'type' },
        { text: 'Valor', value: 'value' },
        { text: 'Ação', value: 'action', sortable: false },
      ],
      priceTable: [
        { name: 'Tabela 1', type: 'Porcentagem', value: 5 },
        { name: 'Tabela 2', type: 'Valor', value: 10 },
        { name: 'Tabela 3', type: 'Porcentagem', value: 2 },
        // Adicione mais tabelas conforme necessário
      ],
      newTableName: '',
      selectedType: 'Porcentagem',
      selectedValue: 0,
      editingIndex: null, // Índice da tabela sendo editada, inicialmente nulo
    };
  },
  computed: {
   /*  filterItens() {
      return this.priceTable.map(item => {
        console.log(item);
        if (item.type === 'Porcentagem') {
          item.value = `% ${item.value}`;
        } else if (item.type === 'Valor') {
          item.value = this.$options.filters.dinheiro(item.value)
        }
        return item;
      });
    }, */
  },
  methods: {
    updateValue(value) {
      this.selectedValue += value;
    },
    savePriceTable() {
      if (this.editingIndex !== null) {
        // Editar tabela existente
        this.editExistingTable();
      } else {
        // Adicionar nova tabela
        this.addNewTable();
      }

      // Limpar os campos após salvar ou editar
      this.newTableName = '';
      this.selectedType = 'Porcentagem';
      this.selectedValue = 0;
      this.editingIndex = null;
    },
    addNewTable() {
      const newTable = {
        name: this.newTableName,
        type: this.selectedType,
        value: this.selectedValue,
      };

      this.priceTable.push(newTable);
      this.criarTabelaDePreco(newTable);
    },
    editExistingTable() {
      if (this.editingIndex !== null && this.editingIndex < this.priceTable.length) {
        // Editar tabela existente
        this.priceTable[this.editingIndex].name = this.newTableName;
        this.priceTable[this.editingIndex].type = this.selectedType;
        this.priceTable[this.editingIndex].value = this.selectedValue;
        // Lógica para editar no banco de dados, se necessário
      }
    },
    criarTabelaDePreco(tabelaDePreco) {
      axios
        .post(`${urlApi}/criar-tabela-preco`, tabelaDePreco, {
          headers: configK,
        })
        .then(() => {
          this.$toasted.global.defaultSuccess({
            msg: "Tabela de preço criada com sucesso!",
          });
        })
        .catch(error => {
          // Lógica para exibir erro, se necessário
          console.error(error);
        });
    },
    removePriceTable(item) {
      // Implemente a lógica para remover uma tabela de preço do banco de dados
      const index = this.priceTable.indexOf(item);
      if (index !== -1) {
        this.priceTable.splice(index, 1);
        // Chame a função para remover do banco de dados, se necessário
      }
    },
    editPriceTable(item) {
      // Editar uma tabela existente
      this.newTableName = item.name;
      this.selectedType = item.type;
      this.selectedValue = item.value;
      this.editingIndex = this.priceTable.indexOf(item);
    },
    cancelEdit() {
      // Cancelar a edição, limpar os campos e resetar a variável editingIndex
      this.newTableName = '';
      this.selectedType = 'Porcentagem';
      this.selectedValue = 0;
      this.editingIndex = null;
    },
  },
};
</script>

<style scoped>
/* Estilos do componente, se necessário */
</style>


