/* Proxy de homologação */
const proxy = 'https://web-production-83b4.up.railway.app/'

/* Token de sessão */
export const userKey = '__System__AC__TUBO__DIESEL__*WasLar*AVIZVFX*';

/* PARTE BD - BACK4 */
/* export const urlApiBd = 'http://localhost:1337/parse/functions'; */
export const urlApiBd = `${proxy}https://parseapi.back4app.com/functions`;
export const linkServidorDeArquivos = `https://arquivods.avizvfx.com.br/index.php/f/872`;

/* Configuração header banco - back4app */
export const cofingHeadersBD = {
    "X-Parse-Application-Id": "hKd3adZqL4UD5gqXW6VTARH43ODBzID4JHc68OJS",
    "X-Parse-REST-API-Key": "ltmlE82A6DG8ruZztXWRUiERxIO04BNETl4Qbfqu"
}
export const ocultarKeyBD = {
    "X-Parse-Application-Id": "",
    "X-Parse-REST-API-Key": ""
}

/* API FILES */
export const urlApiFiles = 'https://servfiles-production.up.railway.app';

/* API CONSULTAR CEP */
export const urlApiViaCep = (cep) => `${proxy}https://viacep.com.br/ws/${cep}/json/`;

/* URL VHSYS */
export const urlVhsys = `${proxy}https://api.vhsys.com/v2/`;

export const ocultarKeyVhsys = {
    "access-token": "",
    "secret-access-token": "",
    "cache-control": ""
}

export const UF = [ {
                nome: "Acre",
                sigla: "AC"
            },
            {
                nome: "Alagoas",
                sigla: "AL"
            },
            {
                nome: "Amapá",
                sigla: "AP"
            },
            {
                nome: "Amazonas",
                sigla: "AM"
            },
            {
                nome: "Bahia",
                sigla: "BA"
            },
            {
                nome: "Ceará",
                sigla: "CE"
            },
            {
                nome: "Distrito Federal",
                sigla: "DF"
            },
            {
                nome: "Espírito Santo",
                sigla: "ES"
            },
            {
                nome: "Goiás",
                sigla: "GO"
            },
            {
                nome: "Maranhão",
                sigla: "MA"
            },
            {
                nome: "Mato Grosso",
                sigla: "MT"
            },
            {
                nome: "Mato Grosso do Sul",
                sigla: "MS"
            },
            {
                nome: "Minas Gerais",
                sigla: "MG"
            },
            {
                nome: "Pará",
                sigla: "PA"
            },
            {
                nome: "Paraíba",
                sigla: "PB"
            },
            {
                nome: "Paraná",
                sigla: "PR"
            },
            {
                nome: "Pernambuco",
                sigla: "PE"
            },
            {
                nome: "Piauí",
                sigla: "PI"
            },
            {
                nome: "Rio de Janeiro",
                sigla: "RJ"
            },
            {
                nome: "Rio Grande do Norte",
                sigla: "RN"
            },
            {
                nome: "Rio Grande do Sul",
                sigla: "RS"
            },
            {
                nome: "Rondônia",
                sigla: "RO"
            },
            {
                nome: "Roraima",
                sigla: "RR"
            },
            {
                nome: "Santa Catarina",
                sigla: "SC"
            },
            {
                nome: "São Paulo",
                sigla: "SP"
            },
            {
                nome: "Sergipe",
                sigla: "SE"
            },
            {
                nome: "Tocantins",
                sigla: "TO"
            }

        ]
        
        export default {
            linkServidorDeArquivos,
            urlVhsys,
            userKey,
            urlApiBd,
            urlApiFiles,
            cofingHeadersBD,
            urlApiViaCep,
            UF,
            ocultarKeyVhsys,
            ocultarKeyBD
        }