import axios from "axios";
import global from "@/global"

axios.defaults.baseURL = global.urlVhsys
axios.defaults.headers.post['Content-Type'] = 'application/json';

/* Config VHSYS */
/* axios.defaults.headers.post['cache-control'] = global.cofingHeadersVhsys["cache-control"];
axios.defaults.headers.post['access-token'] = global.cofingHeadersVhsys["access-token"];
axios.defaults.headers.post['secret-access-token'] = global.cofingHeadersVhsys["secret-access-token"] */

/* Config B4A */
axios.defaults.headers.post['X-Parse-Application-Id'] = global.cofingHeadersBD["X-Parse-Application-Id"];
axios.defaults.headers.post['X-Parse-REST-API-Key'] = global.cofingHeadersBD["X-Parse-REST-API-Key"]

/* axios.interceptors.request.use(function (config) {
    if(config.method == "post"){

        // Hide informações de token do Banco 
        const urlb4a = /\bback4app\b/;
        const matchB4a = config.url.match(urlb4a);

        if(matchB4a){
            delete config.headers['access-token']
            delete config.headers['secret-access-token']
        }

        // Hide informações de token do VhSys 
        const urlvhsys = /\bvhsys\b/;
        const matchVhsys = config.url.match(urlvhsys);

        if(matchVhsys){

            delete config.headers['X-Parse-Application-Id']
            delete config.headers['X-Parse-REST-API-Key']
            delete config.headers['X-Parse-Session-Token']
        }
    }
    return config;
  }, function (error) {
    return Promise.reject(error);
  }); */