<!-- eslint-disable -->
<template>
    <v-row class="px-md-11">
         <!--  CONFIGURAÇÕES LOJA VIRTUAL-->
         <v-col cols="12">
            <v-card color="secondary">
                <v-card-title>
                    <h3 class="text-h6 text-uppercase font-weight-bold">E-commerce</h3>
                    <v-divider class="ml-2"></v-divider>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="12" md="3" class="py-0 pt-5 py-md-3">
                            <v-switch v-model="opcoesLoja.visivel">
                                <template v-slot:label>
                                    <span v-if="opcoesLoja.visivel">Produto visível <v-icon
                                            color="primary">mdi-storefront-check</v-icon></span>
                                    <span v-else>Produto visível <v-icon color="error">mdi-storefront-remove</v-icon></span>
                                </template>
                            </v-switch>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" class="py-0 pt-5 py-md-3">
                            <v-switch v-model="opcoesLoja.freteGratis">
                                <template v-slot:label>
                                    <span v-if="opcoesLoja.freteGratis">Frete grátis <v-icon
                                            color="primary">mdi-archive-star</v-icon>
                                    </span>
                                    <span v-else>Frete grátis <v-icon color="error">mdi-close-circle-multiple</v-icon>
                                    </span>
                                </template>
                            </v-switch>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" class="py-0 pt-5 py-md-3">
                            <v-switch v-model="opcoesLoja.transportadora">
                                <template v-slot:label>
                                    <span v-if="opcoesLoja.transportadora">Transportadora <v-icon
                                            color="primary">mdi-truck-check-outline</v-icon>
                                    </span>
                                    <span v-else>Entrega própria <v-icon color="error">mdi-car-pickup</v-icon>
                                    </span>
                                </template>
                            </v-switch>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" class="py-0 pt-5 py-md-3">
                            <v-switch v-model="opcoesLoja.produtoEmDestaque">
                                <template v-slot:label>
                                    <span v-if="opcoesLoja.produtoEmDestaque">Destaque na loja <v-icon
                                            color="primary">mdi-star-check</v-icon>
                                    </span>
                                    <span v-else>Destaque na loja <v-icon color="error">mdi-star-off</v-icon>
                                    </span>
                                </template>
                            </v-switch>
                        </v-col>
                        <!-- OPÇÕES PRINCIPAIS DA LOJA -->
                        <v-col cols="12" sm="12" class="m">
                            <v-text-field hide-details label="Nome do produto na loja"
                                placeholder="Digite o nome do produto" outlined v-model="opcoesLoja.desc_produto"
                                :loading="loading">
                            </v-text-field>
                        </v-col>
                        <v-spacer />
                        <v-col cols="12" sm="12" md="2" class="ml-auto">
                            <v-text-field hide-details type="number" label="Pédido mínimo" placeholder="Add pedido mínimo"
                                outlined v-model.number="opcoesLoja.pedidoMinimo" :disabled="loading" :loading="loading">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" class="">
                            <v-select v-model="opcoesLoja.tabelaPreco" :items="opTabelaPreco" label="Tabela e-commerce"
                                outlined :disabled="loading" :loading="loading" hide-details clearable>
                            </v-select>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" class="">
                            <v-currency-field hide-details label="Valor para loja" placeholder="Defina o valor exclusivo"
                                outlined v-model.number="dadosProduto.valor" :disabled="loading" :loading="loading">
                            </v-currency-field>
                        </v-col>
                        <!-- EDITOR DE INFORMÇAÕES DO PRODUTO -->
                        <v-col cols="12" sm="12" class="pa-6 my-4">
                                    <v-row no-gutters align="center" align-content="center" justify="center"><v-col
                                            cols="12" class="primary">
                                            <VueEditor
                                                :customModules="customModulesForEditor"
                                                :editorOptions="editorSettings" :editorToolbar="customToolbar"
                                                class="red---text" id="editorFomCol1" v-model="ContentOportunidade">
                                            </VueEditor>

                                        </v-col></v-row>
                                </v-col>

                        <v-col cols="12" sm="12" class="mb-2">
                            <span class="d-flex align-center text-caption primary--text text-uppercase">Categorias do produto na loja <v-divider
                                    class="ml-2 linha-endereco" /></span>
                            <v-chip-group class="mt-2">
                                <v-chip close @click:close="alertaRemoverCategoria(itemCategoria)" color="bg2"
                                    v-for="itemCategoria in opcoesLoja.categorias" :key="itemCategoria.objectId"
                                    class="ma-1 primary--text">{{ itemCategoria.categoria }}
                                </v-chip>
                                <v-btn @click="dialogAddCategoria = true" color="primary" class="ml-2" small fab>
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </v-chip-group>
                            <!-- DIALOGO PARA ADD CATEGORIAS PARA LOJA -->
                            <v-dialog v-model="dialogAddCategoria" max-width="900">
                                <v-card>
                                    <v-toolbar dense color="primary" class="mb-8">
                                        <v-toolbar-title
                                            class="bg2--text text-h6 u-white-space-normal text-subtitle-2 text-uppercase">Selecione
                                            a categoria do produto.</v-toolbar-title>
                                        <v-spacer />
                                        <v-btn small fab @click="dialogAddCategoria = false">
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </v-toolbar>
                                    <v-card-text>
                                        <v-autocomplete return-object v-model="categoriasAtivasDoProduto"
                                            :items="categoriasParaLoja" key="objectId" item-text="categoria"
                                            item-value="categoria" outlined chips small-chips label="CATEGORIAS DA LOJA"
                                            multiple>
                                        </v-autocomplete>
                                    </v-card-text>

                                </v-card>
                            </v-dialog>
                            <!-- DIALOGO PARA REMOVER CATEGORIA DA LOJA -->
                            <v-dialog v-model="dialogRemoverCategoria" max-width="290">
                                <v-card>
                                    <v-card-title class="text-h6 u-white-space-normal text-subtitle-2 text-uppercase">
                                        Tem certeza que deseja remover a categoria?
                                    </v-card-title>

                                    <v-card-text>
                                        <v-btn class="mr-3 my-2" dense color="primary" @click="fecharDialogCategoria()">
                                            CANCELAR
                                        </v-btn>

                                        <v-btn class="my-2" dense color="error" @click="removerCategoria()">
                                            REMOVER
                                        </v-btn>
                                    </v-card-text>
                                </v-card>
                            </v-dialog>
                        </v-col>

                        <!-- Dimensões -->
                        <v-col cols="12" sm="12" md="12" class="my-0 py-0 text-sm-left">
                            <span class="text-caption primary--text text-uppercase d-flex align-center">Dimensões da
                                embalagem (Em centímetros)<v-divider class="ml-2 linha-endereco" /></span>
                            <v-row class="mt-0">
                                <v-col cols="12" xs="12" md="4">
                                    <v-text-field hide-details type="number" label="Altura" placeholder="Add altura"
                                        outlined v-model.number="opcoesLoja.altura" :disabled="loading" :loading="loading">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" md="4">
                                    <v-text-field hide-details type="number" label="Largura" placeholder="Add largura"
                                        outlined v-model.number="opcoesLoja.largura" :disabled="loading" :loading="loading">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" md="4">
                                    <v-text-field hide-details type="number" label="Comprimento"
                                        placeholder="Add comprimento" outlined v-model.number="opcoesLoja.comprimento"
                                        :disabled="loading" :loading="loading">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" class="my-3 py-0">
                                    <v-row no-gutters align="end" align-content="end" justify="center" class="ma-0 pa-0">
                                        <v-col cols="9" class="ma-0 pa-0">
                                            <!-- Adicionar novas imagens - Informações -->
                                            <p class="text-caption primary--text text-uppercase">Adicione abaixo
                                                novas imagens para a loja &nbsp;&nbsp; - &nbsp;&nbsp;

                                                <v-btn outlined @click="showKeyFilesDs = !showKeyFilesDs" color="error"
                                                    small class="ma-0 px-2"> ACESSAR SERVIDOR
                                                    <v-icon v-if="showKeyFilesDs" right>key</v-icon>
                                                    <v-icon v-else right>key_off</v-icon>
                                                </v-btn>
                                                &nbsp; - &nbsp;&nbsp; <v-chip outlined
                                                    :color="isEditarImgParaLoja ? 'error' : 'primaryMn'"> <v-switch
                                                        color="error" class="mt-0 pt-0" hide-details dense
                                                        v-model="isEditarImgParaLoja">
                                                        <template v-slot:label>
                                                            <span :class="{ 'c-text--error': isEditarImgParaLoja }"
                                                                class="text-caption primaaryMn--text text-uppercase">
                                                                REMOVER IMAGENS
                                                            </span>
                                                        </template>
                                                    </v-switch>
                                                </v-chip>
                                                <v-snackbar timeout="-1" color="black" v-model="showKeyFilesDs">
                                                    <div class="pa-3 c-key-servidor-files">
                                                        <span class="error--text mb-1 pa-0">CREDENCIAIS</span>
                                                        <span>Usuário: ds</span>
                                                        <span>Senha: Files102030*</span>
                                                        <v-btn @click="abrirServidorDeArquivos()" class="mt-2">LOGAR
                                                            NO SERVIDOR DE ARQUIVOS<v-icon right>cloud_upload</v-icon>
                                                        </v-btn>

                                                        <v-btn absolute top right color="error" fab
                                                            @click="showKeyFilesDs = false">
                                                            <v-icon>close</v-icon>
                                                        </v-btn>
                                                    </div>
                                                </v-snackbar>
                                            </p>
                                            <!-- Adicionar novas imagens - IMAGENS -->
                                            <v-item-group class="ma-0 pa-0">
                                                <v-container class="ma-0 pa-0">
                                                    <v-row no-gutters align="end" align-content="end" justify="start">
                                                        <v-col v-for="imgproduto in novasImagensParaLoja"
                                                            :key="imgproduto.objectId"
                                                            :cols="imgproduto.objectId === keyColuna ? calcColuna : 3"
                                                            class="mx-3 pa-0 py-3">
                                                            <v-img v-if="imgproduto.personalizada"
                                                                @click="abrirLinkImg(`${imgproduto.url}/preview`)"
                                                                :key="imgproduto.objectId"
                                                                max-width="160px"
                                                                min-width="80px"
                                                                min-height="80px"
                                                                max-height="160px"
                                                                :src="imgproduto.url === '' ? require('@/assets/img.png') : imgproduto.url + '/preview'"
                                                                container class="rounded-lg mb-4 u-cursor"
                                                                :class="{ 'u-events-none': imgproduto.url === '' }">
                                                            </v-img>
                                                            <v-img v-else @click="abrirLinkImg(imgproduto.url)"
                                                                :src="imgproduto.url" container
                                                                class="rounded-lg mb-4 u-cursor"
                                                                max-width="160px"
                                                                min-width="80px"
                                                                min-height="80px"
                                                                max-height="160px"
                                                                >
                                                            </v-img>
                                                            <v-text-field @blur="resetCalcColuna"
                                                                @focus="setUrlImgLoja(imgproduto.objectId)" dense
                                                                :disabled="!imgproduto.personalizada" hide-details outlined
                                                                label="URL da imagem" v-model="imgproduto.url" class="">
                                                                <template v-slot:append-outer>
                                                                    <v-btn v-show="isEditarImgParaLoja" icon
                                                                        class="ma-0 pb-2"
                                                                        @click="removerImgLoja(imgproduto)">
                                                                        <v-icon color="error">delete_forever</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                            </v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" xs="6" md="1" class="my-0 pb-auto pb-md-16">
                                                            <v-btn @click="addNovaImgParaLoja" color="primary" class="ml-2"
                                                                small fab>
                                                                <v-icon>mdi-plus</v-icon>
                                                            </v-btn>
                                                        </v-col>
                                                        <!-- <v-col cols="12" xs="12" md="8" class="pt-4">
                                                    <div v-for="img in novasImagensParaLoja" :key="img.objectId"
                                                        class="ma-0 pa-0 c-img-loja">
                                                        <v-text-field :key="img.objectId" dense class="c-img-loja-item"
                                                            v-model="img.url"
                                                            hint="Clique na nuvem para acessar o servidor de arquivos, gerar uma url, copiar e cole a url aqui."
                                                            persistent-hint append-outer-icon="cloud_upload" outlined
                                                            label="URL da imagem" persistent-placeholder
                                                            placeholder="Cole aqui a url da imagem">
                                                            <template v-slot:append-outer>
                                                                <v-btn :disabled="img.url === ''" class="ma-0" fab small
                                                                    @click="salvarNovaImgParaLoja(img)">
                                                                    <v-icon color="primary">save</v-icon>
                                                                </v-btn>
                                                            </template>
                                                        </v-text-field>
                                                    </div>
                                                </v-col> -->
                                                    </v-row>
                                                </v-container>
                                            </v-item-group>
                                            <!-- ## FIM Adicionar novas imagens -->
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12">
            <v-card color="secondary">
                <v-card-title>
                    <h3 class="text-h6 text-uppercase font-weight-bold">Dados do ERP</h3><v-btn v-if="loading"
                        color="error" class="mx-3 black--text font-weight-bold mt-1 mt-sm-0" x-small
                        @click="loading = !loading">CANCELAR CONSULTA</v-btn>
                    <v-divider class="ml-2"></v-divider>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <!-- Dados do Produto -->
                        <v-col cols="12" sm="12" md="12" class="text-ringht d-flex justify-end mt-n8">
                            <v-switch readonly :value="dadosProduto.status_produto"
                                :label="`Situação - ${dadosProduto.status_produto ? 'Ativo' : 'Inativo'}`" hide-details
                                class="pl-3">
                            </v-switch>
                        </v-col>

                        <v-col cols="12" sm="12" md="7" class="py-0 pt-5 py-md-3">
                            <v-text-field class="u-disabled" label="Nome" placeholder="Digite o nome do produto" outlined
                                :value="dadosProduto.desc_produto" disabled :loading="loading"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" class="py-0 pt-5 py-md-3">
                            <v-text-field class="u-disabled" label="Código do produto"
                                placeholder="Digite o código do produto" outlined :value="dadosProduto.cod_produto" disabled
                                :loading="loading"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="2" class="py-0 pt-5 py-md-3">
                            <v-select class="u-disabled" :value="dadosProduto.tipo_produto" :items="opTipo" label="Tipo"
                                outlined disabled :loading="loading">
                            </v-select>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" class="py-0 pt-5 py-md-3">
                            <v-currency-field class="u-disabled" prefix="R$" label="Valor de venda"
                                placeholder="Digite o valor de venda" outlined :value="dadosProduto.valor_produto" disabled
                                :loading="loading"></v-currency-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" class="py-0 pt-5 py-md-3">
                            <v-text-field class="u-disabled" label="Marca" placeholder="Digite a marca" outlined
                                :value="dadosProduto.marca_produto" disabled :loading="loading"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" class="py-0 pt-5 py-md-3">
                            <v-text-field class="u-disabled" label="Fornecedor" placeholder="Digite o fornecedor" outlined
                                :value="dadosProduto.fornecedor_produto" disabled :loading="loading"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" class="py-0 text-left">
                            <v-text-field class="u-disabled" prepend-inner-icon="mdi-barcode-scan"
                                label="Código de barras interno" placeholder="Digite o código de barras" outlined
                                :value="dadosProduto.codigo_barra_produto" disabled :loading="loading">
                            </v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="2" class="py-0">
                            <span>Peso - em Kg</span>
                            <v-text-field class="u-disabled" label="Bruto" placeholder="Digite o bruto" outlined
                                :value="dadosProduto.peso_produto" disabled :loading="loading">
                            </v-text-field>
                            <v-text-field class="u-disabled" label="Líquido" placeholder="Digite o líquido" outlined
                                :value="dadosProduto.peso_liq_produto" disabled :loading="loading">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" class="py-0 pt-5">
                            <v-text-field class="u-disabled" label="Tamanho do produto" placeholder="Digite o tamanho"
                                outlined :value="dadosProduto.tamanho_produto" disabled :loading="loading">
                            </v-text-field>
                            <v-text-field class="u-disabled" label="Unidade" placeholder="Digite a unidade" outlined
                                :value="dadosProduto.unidade_produto" disabled :loading="loading" hint="Ex.: Pç, Kg, Un"
                                persistent-hint>
                            </v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="7" class="py-0 pt-5">
                            <v-text-field class="u-disabled" label="Categoria do Produto" placeholder="Categoria do Produto"
                                outlined :value="dadosProduto.id_categoria" disabled :loading="loading"></v-text-field>
                            <span class="d-flex align-center">Subcategorias <v-divider class="ml-2 linha-endereco" /></span>
                            <v-chip-group class="mt-2">
                                <v-chip color="bg2" v-for="item in dadosProduto.subcategoria" :key="item.id_subcategoria"
                                    class="ma-1 primary--text">{{ item.nome_subcategoria }}</v-chip>
                            </v-chip-group>
                            <!-- IMAGENS DO PRODUTO -->
                            <v-item-group>
                                <v-container>
                                    <v-row>
                                        <v-col v-for="img in dadosProduto.imagens" :key="img.id_foto" cols="12" md="4">
                                            <v-img :src="img.foto_thumb" container class="ma-2 rounded-lg"></v-img>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-item-group>
                            <!-- ## FIM IMAGENS DO PRODUTO -->
                        </v-col>
                        <v-col cols="12" sm="12" md="12" class="my-0 py-0 text-sm-left">
                            <span class="text-caption primary--text text-uppercase d-flex align-center">Dados para o
                                Estoque<v-divider class="ml-2 linha-endereco" /></span>
                            <v-row class="mt-0">
                                <v-col cols="4">
                                    <v-text-field class="u-disabled" prepend-inner-icon="mdi-chart-timeline"
                                        label="Localização no estoque" placeholder="Digite a localização" outlined
                                        :value="dadosProduto.localizacao_produto" disabled :loading="loading"
                                        hint="Exe. EST 2 / PRAT C" persistent-hint>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field label="Estoque atual" placeholder="0" outlined disabled class="u-disabled"
                                        hint="Calc. Automático" persistent-hint :value="dadosProduto.estoque_produto
                                            ">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-text-field class="u-disabled" hide-spin-buttons label="Estoque mínimo" type="number"
                                        placeholder="Digite o estoque mínimo" outlined
                                        :value.number="dadosProduto.minimo_produto" disabled :loading="loading">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-text-field class="u-disabled" hide-spin-buttons type="number" label="Estoque máximo"
                                        placeholder="Digite o estoque máximo" outlined
                                        :value.number="dadosProduto.maximo_produto" disabled :loading="loading">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" class="py-0 pt-5">
                            <v-textarea :loading="loading" class="u-disabled" disabled filled label="Observações do produto"
                                auto-grow :value="dadosProduto.obs_produto"></v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
  
<script>

/* 
Resposta da api vhsys para listar categorias:
opCategoriaProduto: [
            {
                id_categoria: 123456789,
                atalho_categoria: "nome-da-categoria",
                nome_categoria: "Nome da categoria",
                status_categoria: "Ativo",
                data_cad_categoria: "00-00-0000 00:00:00",
                data_mod_categoria: "00-00-0000 00:00:00",
                lixeira: "Nao",
                subcategorias: [
                    {
                        id_subcategoria: 123,
                        nome_subcategoria: "Nome da subcategoria"
                    }
                ]
            }
        ], 
*/

import barramento from "@/barramento"
import axios from "axios";
import { linkServidorDeArquivos } from "@/global";

import { VueEditor } from "vue2-editor";
import ImageResize from "quill-image-resize-module";

export default {
    name: "Produtos2-screen",
    props: {},
    components: { VueEditor },
    data: (vm) => ({
        ContentOportunidade: '',
        customModulesForEditor: [
        { alias: "imageResize", module: ImageResize }
      ],
        editorSettings: {
            modules: {
                imageResize: {}
            }
        },
        customToolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ align: [] }],
            ["bold", "italic", "underline", "strike", "link"],
            ["image", "code-block", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }], // sobrescrito/subscrito
            [{ indent: "-1" }, { indent: "+1" }], // recuo/recuo

            [{ color: [] }, { background: [] }], // dropdown com padrões
            ["clean"]
        ],
        isEditarImgParaLoja: false,
        keyColuna: '',
        calcColuna: 3,
        idArtificialImg: 1,
        novasImagensParaLoja: [],
        showKeyFilesDs: false,
        dialogRemoverCategoria: false,
        dialogAddCategoria: false,
        notificacaoCategoria: '',
        urlBase: 'https://proxyintereventos-production.up.railway.app/https://arquivods.avizvfx.com.br/remote.php/dav/files/aviz/',
        custUrl: 'https://arquivods.avizvfx.com.br/',
        directoryItems: [],
        opTabelaPreco: ['Tabela-e-commerce', 'Tabela-5%', 'Tabela+5%'],
        opTipo: ['Produto', 'Serviço'],
        categoriasAtivasDoProduto: [],
        categoriasParaLoja: [
            {
                objectId: 1,
                categoria: 'a'
            },
            {
                objectId: 2,
                categoria: 'b'
            },
            {
                objectId: 3,
                categoria: 'c'
            },
            {
                objectId: 4,
                categoria: 'd'
            },
        ],
        categoriaSelecionadaParaRemocao: -1,
        opcoesLoja: {
            objectId: null,
            status_produto: false,
            desc_produto: '',
            cod_produto: 'id' in vm.$route.params ? vm.$route.params.id : '',
            valor_produto: '',
            pedidoMinimo: 1,
            marca_produto: '',
            fornecedor_produto: '',
            peso_produto: '',
            unidade_produto: '',
            estoque_produto: '',
            minimo_produto: '',
            maximo_produto: '',
            categorias: [],
            imagens: [],
        },
        loading: false,
        dadosProduto: {
            objectId: null,
            status_produto: false,
            desc_produto: '',
            cod_produto: 'id' in vm.$route.params ? vm.$route.params.id : '',
            tipo_produto: '',
            valor_produto: '',
            marca_produto: '',
            fornecedor_produto: '',
            peso_produto: '',
            peso_liq_produto: '',
            tamanho_produto: '',
            unidade_produto: '',
            codigo_barra_produto: '',
            localizacao_produto: '',
            estoque_produto: '',
            minimo_produto: '',
            maximo_produto: '',
            obs_produto: '',
            id_categoria: null,
            subcategoria: [],
            imagens: [],
        },
    }),
    computed: {},
    watch: {
        categoriasAtivasDoProduto: {
            handler(novo) {
                this.opcoesLoja.categorias = [...novo];
            },
            deep: true,
            immediate: true
        },
    },
    mounted() {
    },
    methods: {
        resetCalcColuna() {
            this.calcColuna = 3
        },
        setUrlImgLoja(keyColuna) {
            this.keyColuna = keyColuna
            this.calcColuna = -1
        },
        addNovaImgParaLoja() {
            this.novasImagensParaLoja.push({
                objectId: `add${++this.idArtificialImg}`,
                url: '',
                personalizada: true
            })
        },
        removerImgLoja(img) {
            const index = this.novasImagensParaLoja.findIndex(ele => ele.objectId === img.objectId);
            this.novasImagensParaLoja.splice(index, 1)
        },
        /* salvarNovaImgParaLoja(img) {
            const index = this.novasImagensParaLoja.findIndex(ele => ele.objectId === img.objectId);

            console.log('fora do if', index)
            if (index !== -1) {
                // Se a imagem já existe, atualize a URL
                console.log('entrou no if')
                this.$set(this.novasImagensParaLoja, index, { ...this.novasImagensParaLoja[index], url: img.url });
            } else {
                // Se a imagem não existe, adicione ao array
                this.novasImagensParaLoja.push(img);
            }
        }, */
        abrirLinkImg(link) {
            window.open(link, '_blank');
        },
        abrirServidorDeArquivos() {
            window.open(linkServidorDeArquivos, '_blank');
        },
        fecharDialogCategoria() {
            this.dialogRemoverCategoria = false
            this.categoriaSelecionadaParaRemocao = -1
        },
        removerCategoria() {
            this.opcoesLoja.categorias.splice(this.categoriaSelecionadaParaRemocao, 1)
            this.dialogRemoverCategoria = false
            this.categoriaSelecionadaParaRemocao = -1
            this.categoriasAtivasDoProduto = [...this.opcoesLoja.categorias]
        },
        alertaRemoverCategoria(categoria) {
            this.categoriaSelecionadaParaRemocao = this.opcoesLoja.categorias.indexOf(categoria);
            this.dialogRemoverCategoria = true
        },
        async carregarCategorias(idCategoria) {
            const categoria = await axios.get(`/categorias/${idCategoria}`, { headers: JSON.parse(sessionStorage.getItem('keyVhsys__aviz__user')) })
            return categoria.data.data.nome_categoria
        },

        carregarProduto(id) {
            if (id == 'add') return;
            barramento.enviarProgresso(true)

            axios
                .get(`/produtos/${id}`, {
                    headers: JSON.parse(sessionStorage.getItem('keyVhsys__aviz__user'))
                }).then(async res => {

                    /*  this.opcoesLoja = res.data.result.lojaVirtual
                     delete res.data.result.lojaVirtual */
                    /* this.dadosProduto = res.data.data */
                    console.log('res', res)

                    this.dadosProduto.status_produto = res.data.data.status_produto
                    this.dadosProduto.desc_produto = res.data.data.desc_produto
                    this.dadosProduto.cod_produto = res.data.data.cod_produto
                    this.dadosProduto.tipo_produto = res.data.data.tipo_produto
                    this.dadosProduto.valor_produto = res.data.data.valor_produto
                    this.dadosProduto.marca_produto = res.data.data.marca_produto
                    this.dadosProduto.fornecedor_produto = res.data.data.fornecedor_produto
                    this.dadosProduto.peso_produto = res.data.data.peso_produto
                    this.dadosProduto.peso_liq_produto = res.data.data.peso_liq_produto
                    this.dadosProduto.tamanho_produto = res.data.data.tamanho_produto
                    this.dadosProduto.unidade_produto = res.data.data.unidade_produto
                    this.dadosProduto.codigo_barra_produto = res.data.data.codigo_barra_produto
                    this.dadosProduto.localizacao_produto = res.data.data.localizacao_produto
                    this.dadosProduto.estoque_produto = res.data.data.estoque_produto
                    this.dadosProduto.minimo_produto = res.data.data.minimo_produto
                    this.dadosProduto.maximo_produto = res.data.data.maximo_produto
                    this.dadosProduto.obs_produto = res.data.data.obs_produto
                    this.dadosProduto.id_categoria = await this.carregarCategorias(res.data.data.id_categoria)
                    this.dadosProduto.subcategoria = res.data.data.subcategoria
                    this.dadosProduto.imagens = res.data.data.imagens


                    this.opcoesLoja.status_produto = res.data.data.status_produto
                    this.opcoesLoja.desc_produto = res.data.data.desc_produto
                    this.opcoesLoja.cod_produto = res.data.data.cod_produto
                    this.opcoesLoja.valor_produto = res.data.data.valor_produto
                    this.opcoesLoja.marca_produto = res.data.data.marca_produto
                    this.opcoesLoja.fornecedor_produto = res.data.data.fornecedor_produto
                    this.opcoesLoja.peso_produto = res.data.data.peso_produto
                    this.opcoesLoja.unidade_produto = res.data.data.unidade_produto
                    this.opcoesLoja.estoque_produto = res.data.data.estoque_produto
                    this.opcoesLoja.minimo_produto = res.data.data.minimo_produto
                    this.opcoesLoja.maximo_produto = res.data.data.maximo_produto

                    this.opcoesLoja.categorias[0] = {
                        objectId: res.data.data.id_categoria,
                        categoria: await this.carregarCategorias(res.data.data.id_categoria)
                    }
                    res.data.data.subcategoria.forEach(cate => {
                        this.opcoesLoja.categorias.push(
                            {
                                objectId: cate.id_subcategoria,
                                categoria: cate.nome_subcategoria
                            }
                        )
                    })
                    res.data.data.imagens.filter(ele => {
                        this.novasImagensParaLoja.push(
                            {
                                objectId: `${ele.id_foto}`,
                                url: ele.foto_grande,
                                personalizada: false
                            }
                        )
                    })
                    this.novasImagensParaLoja.push({
                        objectId: `add${this.idArtificialImg}`,
                        url: '',
                        personalizada: true
                    })
                    /*  res.data.data.imagens.filter(ele => {
                         console.log('ele', ele)
                         this.opcoesLoja.imagens.push(
                             {
                                 objectId: ele.id_foto,
                                 url: ele.foto_grande
                             }
                         )
                     }) */
                    this.categoriasAtivasDoProduto = this.opcoesLoja.categorias
                    this.categoriasParaLoja = [...this.opcoesLoja.categorias]

                    console.log('resssss', this.dadosProduto, this.novasImagensParaLoja)

                    this.$toasted.global.defaultSuccess('Dados do produto carregado');
                    barramento.enviarProgresso(false)

                }).catch(err => {
                    this.$toasted.global.defaultError(err.response.data.message);
                    barramento.enviarProgresso(false)
                })
        },
    },
    created() {
        if ('id' in this.$route.params) {
            this.carregarProduto(this.$route.params.id)
        }

        /* this.server = new Server({
            basicAuth: {
                password: "FilesDs102030*",
                username: "aviz",
            },
            url: `${proxyy}http://filesds.procis.com.br`,
        });
        this.client = new Client(this.server);
     
        this.client.getSystemInfo()
        .then(async (systemInfo) => {
            console.log('systemInfo', systemInfo);
        }).catch(err => {
            console.log('err', err);
        }); */

    },
    beforeMount() { },
};
</script>
  
<style>
#editorFomCol1 .ql-editor {
    color: black;
}

.c-text--error {
    color: #ff5252 !important;
}

.c-key-servidor-files {
    text-align: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.c-img-loja-item .v-input__append-outer {
    margin-top: 0px !important;
    padding: 0px !important;
}
</style>