import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import '@/cofing/axios'
import '@/cofing/icons'
import '@/cofing/moeda'
import '@/cofing/msg'
import { vMaska } from "maska"

Vue.filter('dinheiro', valor => {
	return valor == null || isNaN(valor) ? 'R$ 0,00' : valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
})

Vue.filter('dinheiroDecimal', valor => {
	return valor == null || isNaN(valor) ? 'R$ 0,00' : valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL', minimumFractionDigits: 4});
})

Vue.config.productionTip = false
Vue.directive("maska", vMaska)


new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
