import Vue from 'vue'
export default new Vue({
    methods: {
        enviarUserSuspeito(novosUser) {
            this.$emit('getUserSuspeito', novosUser)
        },
        getUserSuspeito(callback) {
            this.$on('getUserSuspeito', callback)
        },
        enviarProgresso(evento) {
            this.$emit('getProgresso', evento)
        },
        getProgresso(callback) {
            this.$on('getProgresso', callback)
        },

    }
})