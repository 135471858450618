<template>
    <v-row>
        <v-col cols="12" sm="12" class="ma-0 pa-0">
            <v-card class="mx-auto" max-width="400" tile>
                <v-list dense id="c-list-empresas" class="ma-0 pa-0">
                    <v-list-item-group v-model="empresaSelecionada" color="primary">
                        <v-list-item v-for="(item, i) in dataEmpresasBD" :key="i">
                            <v-list-item-avatar>
                                <v-avatar size="100%" color="indigo">
                                    <img :src="getLogo(item.logo)">
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-left" v-text="item.nomeFantasia"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-card>
        </v-col>
        <v-col cols="12">
            <v-card color="secondary">
                <v-card-title>
                    <h3 class="text-h6 text-uppercase font-weight-bold">Dados da empresa</h3><v-btn v-if="loading"
                        color="error" class="mx-3 black--text font-weight-bold mt-1 mt-sm-0" x-small
                        @click="loading = !loading">CANCELAR CONSULTA</v-btn>
                    <v-divider class="ml-2"></v-divider>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <!-- Dados da empresa -->
                        <v-col cols="12" sm="12" md="3" class="py-0 py-md-3">
                            <v-text-field v-maska data-maska="##.###.###/####-##" label="CNPJ"
                                placeholder="Digite o CNPJ da empresa" outlined v-model="dadosEmpresa.cnpj"
                                append-icon="manage_search" @click:append="consultarCNPJ" v-on:keyup.enter="consultarCNPJ"
                                :disabled="loading" :loading="loading"></v-text-field>

                        </v-col>
                        <v-col cols="12" sm="12" md="3" class="py-0 pt-5 py-md-3">
                            <v-select :key="1" v-model="dadosEmpresa.tipoPessoa" :items="opTipoPessoa"
                                label="Tipo de pessoa" outlined :disabled="loading" :loading="loading"></v-select>
                        </v-col>

                        <v-col cols="8" sm="5" md="2" class="py-0 py-md-3">
                            <v-text-field label="Inscrição estadual" placeholder="Digite a inscrição estadual" outlined
                                v-model="dadosEmpresa.inscricaoEstadual"></v-text-field>
                        </v-col>
                        <v-col cols="1" sm="4" md="1" class="py-0 py-md-1">
                            <v-checkbox v-model="dadosEmpresa.isento" label="Isento"></v-checkbox>
                        </v-col>
                        <v-col cols="1" sm="4" md="1" class="py-0 py-md-1">
                            <v-checkbox :key="dadosEmpresa.objectId" v-model="dadosEmpresa.mei" :input-value="dadosEmpresa.mei" label="MEI"></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" class="py-0">
                            <v-text-field label="Razão social" placeholder="Digite a razão social" outlined
                                v-model="dadosEmpresa.razaoSocial" :disabled="loading" :loading="loading"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" class="py-0">
                            <v-text-field label="Nome fantasia" placeholder="Digite o nome fantasia" outlined
                                v-model="dadosEmpresa.nomeFantasia"></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="6" md="3" class="py-0">
                            <v-text-field label="Inscrição Municipal" placeholder="Digite a inscrição municipal" outlined
                                v-model="dadosEmpresa.inscricaoMunicipal"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" md="3" class="py-0">
                            <v-select :key="2" v-model="dadosEmpresa.regimeTributario" :items="opRegimeTributario"
                                label="Regime Tributário" outlined>
                            </v-select>
                        </v-col>

                        <v-col cols="6" sm="6" md="3" class="py-0">
                            <v-text-field label="CNAE" placeholder="Digite o CNAE" outlined v-model="dadosEmpresa.cnae"
                                :disabled="loading" :loading="loading"></v-text-field>
                        </v-col>
                        <v-col v-if="isPessoaJuridica" cols="6" sm="6" md="3" class="py-0">
                            <v-text-field label="RNTRC" placeholder="Digite o rntrc" outlined
                                v-model="dadosEmpresa.rntrc"></v-text-field>
                        </v-col>
                        <v-col cols="1" sm="4" md="3" class="py-0">
                            <v-file-input v-model="logo" label="LOGO EMPRESA" class="c-logo-empresa" color="segunda"
                                outlined prepend-icon="" prepend-inner-icon="wallpaper">
                            </v-file-input>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" class="py-0">
                            <v-text-field label="Responsável" placeholder="Digite o nome do responsável" outlined
                                v-model="dadosEmpresa.responsavel" hide-details></v-text-field>
                        </v-col>

                        <v-col v-if="!isPessoaJuridica" cols="12" sm="12" md="3" class="py-0">
                            <v-text-field v-maska data-maska="###.###.###-##" label="CPF" placeholder="Digite o cpf"
                                outlined v-model="dadosEmpresa.cpf"></v-text-field>
                        </v-col>
                        <!-- Endereço empresa -->
                        <!-- Ceparador -->
                        <v-col cols="12" sm="12" md="12" class="py-10 pt-11">
                            <v-row>
                                <v-divider class="ma-2 linha-endereco" />
                                <h6 class="text-caption text-uppercase"> Endereço da empresa</h6> <v-divider
                                    class="ma-2 linha-endereco" />
                            </v-row>
                        </v-col>
                        <!-- Fim ceparador -->
                        <v-col cols="12" sm="12" md="3" class="py-0">
                            <v-text-field v-maska data-maska="##.###-###" label="CEP" placeholder="Digite o CEP" outlined
                                v-model="dadosEmpresaEndereco.cep" append-icon="location_searching"
                                @click:append="consultarCepEmpresa" v-on:keyup.enter="consultarCepEmpresa"
                                :disabled="loading" :loading="loading"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="5" class="py-0">
                            <v-text-field label="Endereço" placeholder="Digite o Endereço" outlined
                                v-model="dadosEmpresaEndereco.endereco" :disabled="loading"
                                :loading="loading"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="2" class="py-0">
                            <v-text-field label="Número" placeholder="Digite o número" outlined
                                v-model="dadosEmpresaEndereco.numero"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" class="py-0">
                            <v-text-field label="Bairro" placeholder="Digite o bairro" outlined
                                v-model="dadosEmpresaEndereco.bairro" :disabled="loading" :loading="loading"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="5" class="py-0">
                            <v-text-field label="Complemento" placeholder="Digite o complemento" outlined
                                v-model="dadosEmpresaEndereco.complemento"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="2" class="py-0">
                            <v-text-field label="Cidade" placeholder="Digite a cidade" outlined
                                v-model="dadosEmpresaEndereco.cidade" :disabled="loading" :loading="loading"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="2" class="py-0">
                            <v-select return-object v-model="dadosEmpresaEndereco.estado" :items="opEstado"
                                :loading="loading" item-value="nome" item-text="nome" label="Estado" outlined :key="3">
                            </v-select>

                        </v-col>

                        <!-- Dados de contato da empresa -->
                        <!-- Ceparador -->
                        <v-col cols="12" sm="12" md="12" class="py-10 pt-11">
                            <v-row>
                                <v-divider class="ma-2 linha-endereco" />
                                <h6 class="text-caption text-uppercase"> DADOS PARA CONTATO</h6> <v-divider
                                    class="ma-2 linha-endereco" />
                            </v-row>
                        </v-col>
                        <!-- Fim ceparador -->
                        <v-col cols="12" sm="12" md="3" class="py-0">
                            <v-text-field v-maska data-maska="(##) ####-####" label="Telefone"
                                placeholder="Digite o telefone" outlined
                                v-model="dadosEmpresaEndereco.telefone"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="2" class="py-0">
                            <v-text-field v-maska data-maska="(##) # ####-####" label="Celular"
                                placeholder="Digite o celular" outlined
                                v-model="dadosEmpresaEndereco.celular"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="2" class="py-0">
                            <v-text-field label="Website" placeholder="Digite o site" outlined
                                v-model="dadosEmpresaEndereco.website"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="2" class="py-0">
                            <v-text-field label="E-mail" placeholder="Digite o e-mail" outlined
                                v-model="dadosEmpresaEndereco.email"></v-text-field>
                        </v-col>

                        <!-- BOTÃO CRIAR EMPRESA -->

                        <v-col cols="12" sm="12" md="12" class="py-4 text-right">
                            <v-btn v-if="dadosEmpresa.objectId !== null" elevation="10" large right color="primary"
                                class="black--text subtitle-1 font-weight-bold" @click="criarEmpresa()" :loading="loading"
                                :disabled="loading">
                                ATUALIZAR DADOS DA EMPRESA<v-icon class="pl-2" dark color="">mdi mdi-reload</v-icon>
                            </v-btn>
                            <v-btn v-else elevation="10" large right color="primary"
                                class="black--text subtitle-1 font-weight-bold" @click="criarEmpresa()" :loading="loading"
                                :disabled="loading">
                                SALVAR DADOS DA EMPRESA<v-icon class="pl-2" dark
                                    color="">mdi-content-save-cog-outline</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12">
            <v-card color="secondary">
                <v-card-title>
                    <h3 class="text-h6 text-uppercase font-weight-bold">Dados da contabilidade</h3>
                    <v-divider class="ml-2"></v-divider>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <!--  Dados CONTADOR -->
                        <v-col cols="12" sm="12" md="3" class="py-0 pt-5 py-md-5">
                            <v-text-field label="Contabilista responsável" placeholder="Digite o nome do contador" outlined
                                v-model="dadosContabilidade.nome" hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" class="py-0 pt-5 py-md-5">
                            <v-text-field v-maska data-maska="['###.###.###-##', '##.###.###/####-##']" label="CNPJ"
                                placeholder="Digite o CNPJ" outlined v-model="dadosContabilidade.cnpj"
                                hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" class="py-0 pt-5 py-md-5">
                            <v-text-field v-maska data-maska="###.###.###-##" label="CPF" placeholder="Digite o cpf"
                                outlined v-model="dadosContabilidade.cpf" hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="2" class="py-2 pt-5 py-md-5">
                            <v-text-field label="CRC" placeholder="Digite o CRC" outlined v-model="dadosContabilidade.crc"
                                hide-details></v-text-field>
                        </v-col>
                        <!-- <v-spacer class="mx-auto"></v-spacer> -->
                        <!-- Contato contador -->
                        <v-col cols="12" sm="12" md="3" class="py-2">
                            <v-text-field v-maska data-maska="(##) ####-####" label="Telefone"
                                placeholder="Digite o telefone" outlined v-model="dadosContabilidadeEndereco.telefone"
                                hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" class="py-2">
                            <v-text-field v-maska data-maska="(##) # ####-####" label="Celular"
                                placeholder="Digite o celular" outlined v-model="dadosContabilidadeEndereco.celular"
                                hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" class="py-2">
                            <v-text-field label="Website" placeholder="Digite o site" outlined
                                v-model="dadosContabilidadeEndereco.website" hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" class="py-2">
                            <v-text-field label="E-mail" placeholder="Digite o e-mail" outlined
                                v-model="dadosContabilidadeEndereco.email" hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" class="d-none d-md-flex">
                            <v-divider class="ma-2 linha-endereco" />
                        </v-col>
                        <!-- Endereço contador -->
                        <v-col cols="12" sm="12" md="3" class="py-0">
                            <v-text-field v-maska data-maska="##.###-###" label="CEP" placeholder="Digite o CEP" outlined
                                v-model="dadosContabilidadeEndereco.cep" append-icon="location_searching"
                                @click:append="consultarCepContador" v-on:keyup.enter="consultarCepContador"
                                :disabled="loading" :loading="loading"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="5" class="py-0">
                            <v-text-field label="Endereço" placeholder="Digite o Endereço" outlined
                                v-model="dadosContabilidadeEndereco.endereco" :disabled="loading"
                                :loading="loading"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="2" class="py-0">
                            <v-text-field label="Número" placeholder="Digite o número" outlined
                                v-model="dadosContabilidadeEndereco.numero"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" class="py-0">
                            <v-text-field label="Bairro" placeholder="Digite o bairro" outlined
                                v-model="dadosContabilidadeEndereco.bairro" :disabled="loading"
                                :loading="loading"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="5" class="py-0">
                            <v-text-field label="Complemento" placeholder="Digite o complemento" outlined
                                v-model="dadosContabilidadeEndereco.complemento"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="2" class="py-0">
                            <v-text-field label="Cidade" placeholder="Digite a cidade" outlined
                                v-model="dadosContabilidadeEndereco.cidade" :disabled="loading"
                                :loading="loading"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="2" class="py-0">
                            <v-select return-object v-model="dadosContabilidadeEndereco.estado" :items="opEstado"
                                item-value="nome" item-text="nome" label="Estado" outlined :loading="loading" :key="4">
                            </v-select>
                        </v-col>
                    </v-row>
                </v-card-text>

            </v-card>
        </v-col>

    </v-row>
</template>
  
<script>
import axios from "axios";
import { urlApiViaCep, urlApiFiles, UF, urlApiBd } from "@/global";
export default {
    name: "Empresa-screen",
    props: {},
    components: {},
    data: () => ({
        logo: null,
        logoBD: {},
        empresaSelecionada: null,
        dataEmpresasBD: [],
        isPessoaJuridica: true,
        loading: false,
        dadosEmpresa: {
            objectId: null
        },
        dadosEmpresaEndereco: {
            objectId: null
        },
        dadosContabilidade: {},
        dadosContabilidadeEndereco: {},
        opTipoPessoa: ['Pessoa Jurídica', 'Pessoa Física'],
        opRegimeTributario: ['Simples Nacional', 'Simples Nacional - excesso de sublimite de receita bruta', 'Regime Normal', 'Lucro Presumido', 'Lucro Real', 'REUC', 'RPA'],
        opEstado: UF
    }),
    computed: {
        getLogo() {
            return data =>{

                return data != undefined ? data.url : ''
            }
        }
    },
    watch: {
        logo(logoNova, logoAntiga) {
            if (logoNova == null && !('name' in this.logoBD)) return

            if (logoNova == null && 'name' in this.logoBD) {
                this.atualizarLogo(this.logoBD.caminho, 'atualizarArquivo')
                return
            }
            if ('name' in logoNova && logoAntiga == null) {
                this.atualizarLogo(logoNova, 'inserirArquivo')
            }
        },
        'dadosEmpresa.tipoPessoa': {
            handler(novo) {
                if (novo == 'Pessoa Física') {
                    this.isPessoaJuridica = false
                } else {
                    this.isPessoaJuridica = true
                }
            },
            immediate: true
        },
        empresaSelecionada: {
            handler(novo, antigo) {
                if (novo == undefined || novo == antigo) return

                this.dadosEmpresaEndereco = Object.assign({}, this.dataEmpresasBD[novo].endereco);
                this.dadosEmpresa = Object.assign({}, this.dataEmpresasBD[novo]);


                /* Aqui eu estou excluindo o endereco do objct pois não quero que ele vá par ao banco, pois a class da empresa não tem endereço, existe uma classe propria para endereço, que mesclo na requisição com os dados da empresa */
                delete this.dadosEmpresa.endereco
            },
        }
    },
    methods: {
        atualizarLogo(logo, acao) {
            let logoAdd = []

            logoAdd.push(logo)

            let formData = new FormData();
            logoAdd.filter((ele) => {
                formData.append('arquivo', ele);
                formData.append('acao', acao);
                formData.append('classBd', 'Empresa');
            });
            axios
                .post(`${urlApiFiles}/UpArquivo`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    if (res.data.arquivoDeletado) {
                        this.logoBD = {}
                        this.$toasted.global.defaultSuccess(false);
                        return
                    }
                    this.logoBD = { ...res.data[0] };
                    this.$toasted.global.defaultSuccess('Dados da logo armazenado!');
                })
                .catch(err => {
                    this.$toasted.global.defaultError(err.response.data.message);
                });
        },
        listarEmpresas() {
            axios.post(`${urlApiBd}/get-empresas`).then(res => {
                this.dataEmpresasBD = res.data.result
                this.$toasted.global.defaultSuccess('Empresas encontradas!');
            }).catch(err => {
                this.$toasted.global.defaultError(err.response.data.message);
            })
        },
        consultarCNPJ() {
            this.loading = true
            axios
                .get('#')
                .then(res => {
                    this.dadosEmpresa.cnpj
                    this.dadosEmpresa.tipoPessoa = this.opTipoPessoa[0]
                    this.dadosEmpresa.razaoSocial = res.data.razao_social
                    this.dadosEmpresa.cnae = res.data.cnae_principal

                    this.dadosEmpresaEndereco.cep = res.data.endereco.cep
                    this.dadosEmpresaEndereco.bairro = res.data.endereco.bairro
                    this.dadosEmpresaEndereco.endereco = res.data.endereco.logradouro
                    this.dadosEmpresaEndereco.numero = res.data.endereco.numero
                    this.dadosEmpresaEndereco.estado = this.opEstado.find(estado => estado.sigla == res.data.endereco.uf)
                    this.dadosEmpresaEndereco.cidade = res.data.endereco.nome_municipio
                    this.$toasted.global.defaultSuccess('CNPJ encontrado!');
                    this.loading = false
                }).catch(err => {
                    this.$toasted.global.defaultError(err.response.data.message);
                    this.loading = false
                })
        },
        criarEmpresa() {
            this.loading = true
            if (this.empresaSelecionada != null) {
                this.dataEmpresasBD[this.empresaSelecionada] = this.dadosEmpresa
                this.dataEmpresasBD[this.empresaSelecionada].endereco = this.dadosEmpresaEndereco
            }
            const dataEmpresa = this.dadosEmpresa
            dataEmpresa.dadosEmpresaEndereco = this.dadosEmpresaEndereco
            dataEmpresa.logo = this.logoBD

            axios
                .post(`${urlApiBd}/cadastrar-empresa`, dataEmpresa)
                .then(res => {
                    this.dadosEmpresa.objectId = res.data.result.idEmpresa
                    this.dadosEmpresaEndereco.objectId = res.data.result.idEndereco
                    this.loading = false
                    this.$toasted.global.defaultSuccess(false);
                }).catch(err => {
                    this.loading = false
                    this.$toasted.global.defaultError(err.response.data.error);
                })
        },
        parseCepECnpj(cep) {
            return cep.replace(/[^0-9]+|\s+/g, "");
        },
        consultarCepEmpresa() {
            this.loading = true
            const cep = this.parseCepECnpj(this.dadosEmpresaEndereco.cep)

            axios.get(urlApiViaCep(cep)).then(res => {
                this.dadosEmpresaEndereco.endereco = res.data.logradouro
                this.dadosEmpresaEndereco.bairro = res.data.bairro
                this.dadosEmpresaEndereco.cidade = res.data.localidade
                this.dadosEmpresaEndereco.estado = this.opEstado.find(estado => estado.sigla == res.data.uf)
                if (res.data.erro) {
                    this.$toasted.global.defaultError('Nenhum dado para o CEP informado');
                }
                this.loading = false
            }).catch(err => {
                err.msg = 'CEP Inválido!'
                this.$toasted.global.defaultError(err.msg);
                this.loading = false
            })
        },
        consultarCepContador() {
            this.loading = true
            const cep = this.parseCepECnpj(this.dadosContabilidadeEndereco.cep)
            axios.get(urlApiViaCep(cep)).then(res => {
                this.dadosContabilidadeEndereco.endereco = res.data.logradouro
                this.dadosContabilidadeEndereco.bairro = res.data.bairro
                this.dadosContabilidadeEndereco.cidade = res.data.localidade
                this.dadosContabilidadeEndereco.estado = this.opEstado.find(estado => estado.sigla == res.data.uf)
                if (res.data.erro) {
                    this.$toasted.global.defaultError('Nenhum dado para o CEP informado');
                }
                this.loading = false
            }).catch(err => {
                err.msg = 'CEP Inválido!'
                this.$toasted.global.defaultError(err.msg);
                this.loading = false
            })
        }
    },
    created() {
        this.listarEmpresas()
    },
    beforeMount() { },
    mounted() { }
};
</script>
  
<style>
.c-logo-empresa .v-label {
    font-size: 11px;
}

.linha-endereco {
    border-style: dashed;
}

#c-list-empresas .v-item-group {
    display: flex;
}
</style>