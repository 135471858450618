import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/dashboard/Dashboard.vue'
import Usuario from '../views/usuario/Usuario.vue'
/* Páginas */
import Login from '@/components/Login.vue'
/* Cliente */
import Cliente from '../views/cliente/Cliente.vue'
import ListarClientes from '../views/cliente/ListarClientes.vue'
import Pedidos from '../views/pedidos.vue'
/* Produtos */
import Produto from '../views/produto/Produto.vue'
import Produto2 from '../views/produto/Produto2.vue'
import ListarProdutos from '../views/produto/ListarProdutos.vue'
import TabelaDePreco from '../views/tabelaDePreco.vue'
import Categorias from '../views/categorias.vue'
/* Empresa */
import Empresa from '../views/empresa/Empresa.vue'

import {
  userKey, urlApiBd
} from '@/global'
import axios from "axios";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      isAutenticacao: false,
      autenticado: false
    }

  },
  {
    path: '/Dashboard',
    name: 'Dashboard',
    redirect: '/Dashboard/ListarProdutos',
    components: { Dashboard },
    children: [
      { path: 'Empresa', name: 'Empresa', component: Empresa, meta: { user: {}, isAutenticacao: true, autenticado: false, redirect: false } },
      {
        path: 'ListarClientes', name: 'ListarClientes', component: ListarClientes, meta: { user: {}, isAutenticacao: true, autenticado: false, redirect: false }, children: [
          { path: 'Cliente/:id', name: 'Cliente', component: Cliente, meta: { user: {}, isAutenticacao: true, autenticado: false, redirect: false } },
        ]
      },
      {
        path: 'ListarProdutos', name: 'ListarProdutos', component: ListarProdutos,
        meta: { user: {}, isAutenticacao: true, autenticado: false, redirect: false },
        children: [
          { path: 'Produto/:id', name: 'Produto', component: Produto, meta: { user: {}, isAutenticacao: true, autenticado: false, redirect: false } },
          { path: 'Produto2/:id', name: 'Produto2', component: Produto2, meta: { user: {}, isAutenticacao: true, autenticado: false, redirect: false } },
        ]
      },
      {
        path: 'TabelaDePreco', name: 'TabelaDePreco', component: TabelaDePreco,
        meta: { user: {}, isAutenticacao: true, autenticado: false, redirect: false },
      },
      {
        path: 'Pedidos', name: 'Pedidos', component: Pedidos,
        meta: { user: {}, isAutenticacao: true, autenticado: false, redirect: false },
      },
      {
        path: 'Categorias', name: 'Categorias', component: Categorias,
        meta: { user: {}, isAutenticacao: true, autenticado: false, redirect: false },
      },
      { path: 'Usuario', name: 'Usuario', component: Usuario, meta: { user: {}, isAutenticacao: true, autenticado: false, redirect: false } }
    ],
    meta: { user: {}, isAutenticacao: true, autenticado: false, redirect: false },
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// eslint-disable-next-line
router.beforeEach((to, from, next) => {
  const dataUser = localStorage.getItem(userKey)
  if (to.name !== 'Login') {
    if (!dataUser) {
      to.meta.autenticado = false;
      localStorage.removeItem(userKey);
      next({ name: 'Login' })
      return
    } else {
      axios.post(`${urlApiBd}/validar-token`, {}, {
        headers: {
          "X-Parse-Session-Token": JSON.parse(dataUser).sessionToken
        }
      }).then(res => {
        localStorage.setItem(userKey, JSON.stringify(res.data.result));
        axios.defaults.headers.post['X-Parse-Session-Token'] = res.data.result.sessionToken
        axios.defaults.headers.get['X-Parse-Session-Token'] = res.data.result.sessionToken

        sessionStorage.setItem('keyVhsys__aviz__user', JSON.stringify(res.data.result.cofingHeadersVhsys));
        delete res.data.result.cofingHeadersVhsys

        to.meta.autenticado = true
        to.meta.user = {
          username: res.data.result.username,
          name: res.data.result.name,
          email: res.data.result.email,
        }
        next()
        return
      }).catch((err) => {
        localStorage.removeItem(userKey);
        to.meta.autenticado = false
        err.msg = 'Usuário desconhecido não autorizado!'
        Vue.toasted.global.defaultError(err.msg);
        next({ name: 'Login' })
      });
    }
  } else {
    //Sem dados do user no controler
    if (!dataUser) {
      next()
    } else {
      //Com dados do user no controler executar validação
      next(next({ name: 'Dashboard' }))
    }
  }

})

export default router