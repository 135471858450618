<template>
  <v-row justify="start" align-content="start" align="start" class="ma-0 pa-2 ma-sm-5" >
    <v-col cols="12" sm="12">
      <router-view></router-view>
    </v-col>
  </v-row>
</template>

<script>
import barramento from "@/barramento"
export default {
  name: "Dashboard-screen",
  props: {},
  components: {},
  data: () => ({
    hideGrafico: false
  }),
  computed: {},
  methods: {},
  beforeRouteUpdate(to, from, next) {
  /*    // just use `this`
     if(!to.meta.autenticado){
       this.$router.push('/Login')
     } */
     if(to.path != "/Dashboard"){
      this.hideGrafico = true
    }else{
       this.hideGrafico = false
     }
     next()
   },
  created() {
    barramento.getUserSuspeito((e) => {
      if (e) this.$router.push('/Login')
    });
  },
  beforeMount() { },
  mounted() { }
};
</script>

<style></style>