<template>
  <v-row justify="start" class="ma-0 pa-0">
    <v-menu bottom min-width="200px" rounded offset-y class="ma-0 pa-0">
      <template v-slot:activator="{ on }">
        <v-btn icon x-large v-on="on">
          <v-avatar color="bg" size="48">
            <v-icon color="primary">mdi-account-tie</v-icon>
          </v-avatar>
        </v-btn>
      </template>
      <v-card>
        <v-list-item-content class="justify-center">
          <div class="mx-auto text-center">
            <v-avatar color="bg2" class="mb-2">
              <v-icon color="primary">mdi-account-tie</v-icon>
            </v-avatar>
            <h3>{{ $route.meta.user.name }}</h3>
            <p class="text-caption mt-1">
              {{ $route.meta.user.email }}
            </p>
            <v-divider class="my-3"></v-divider>
            <v-btn depressed rounded text disabled>
              Configurações
            </v-btn>
            <v-divider class="my-3"></v-divider>
            <v-btn depressed rounded text to="/" @click="sair()">
              SAIR
            </v-btn>
          </div>
        </v-list-item-content>
      </v-card>
    </v-menu>
  </v-row>
</template>
  
<script>
import {
  userKey
} from '@/global'
export default {
  name: "Usuário-screen",
  props: {},
  components: {},
  data: () => ({
    user: {
      initials: 'JD',
      fullName: 'John Doe',
      email: 'john.doe@doe.com',
    },
  }),
  computed: {},
  watch: {},
  methods: {
    sair() {
      localStorage.removeItem(userKey);
      this.$router.push({ path: '/'})
    }
  },
  created() { },
  beforeMount() { },
  mounted() { }
};
</script>
  
<style></style>