<template>
    <v-row class="px-md-11">
        <v-col cols="12">
            <v-card color="secondary">
                <v-card-title>
                    <h3 class="text-h6 text-uppercase font-weight-bold">Dados do cliente / fornecedor</h3><v-btn
                        v-if="loading" color="error" class="mx-3 black--text font-weight-bold mt-1 mt-sm-0" x-small
                        @click="loading = !loading">CANCELAR CONSULTA</v-btn>
                    <v-divider class="ml-2"></v-divider>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <!-- Dados do cliente ou fornecedor -->
                        <v-col cols="12" sm="12" md="2" class="py-0 pt-5 py-md-3">
                            <v-select v-model="dadosCliente.tipoCadastro" :items="opTipoCadastro" label="Tipo cadastro"
                                outlined :disabled="loading" :loading="loading"></v-select>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" class="py-0 pt-5 py-md-3">
                            <v-select @change="identificarPessoa" :items="opTipoPessoa" label="Tipo de pessoa" outlined
                                :disabled="loading" :loading="loading" :value="dadosCliente.tipoPessoa"></v-select>
                        </v-col>
                        <v-col v-if="isPessoaJuridica" cols="12" sm="12" md="3" class="py-0 py-md-3">
                            <v-text-field v-maska data-maska="##.###.###/####-##" label="CNPJ"
                                placeholder="Digite o CNPJ da empresa" outlined v-model="dadosCliente.cnpj"
                                append-icon="manage_search" @click:append="consultarCNPJ" v-on:keyup.enter="consultarCNPJ"
                                :disabled="loading" :loading="loading"></v-text-field>

                        </v-col>
                        <v-col v-if="!isPessoaJuridica" cols="12" sm="12" md="3" class="py-0 py-md-3">
                            <v-text-field v-maska data-maska="###.###.###-##" label="CPF" placeholder="Digite o cpf"
                                outlined v-model="dadosCliente.cpf"></v-text-field>
                        </v-col>
                        <v-col cols="8" sm="5" md="2" class="py-0 py-md-3">
                            <v-text-field label="Inscrição estadual" placeholder="Digite a inscrição estadual" outlined
                                v-model="dadosCliente.inscricaoEstadual"></v-text-field>
                        </v-col>
                        <v-col cols="8" sm="5" md="2" class="py-0 py-md-3">
                            <v-text-field label="Inscrição Municipal" placeholder="Digite a inscrição municipal" outlined
                                v-model="dadosCliente.inscricaoMunicipal"></v-text-field>
                        </v-col>
                        <v-col v-if="isPessoaJuridica" cols="12" sm="12" md="6" class="py-0">
                            <v-text-field label="Razão social" placeholder="Digite a razão social" outlined
                                v-model="dadosCliente.razaoSocial" :disabled="loading" :loading="loading"></v-text-field>
                        </v-col>
                        <v-col v-if="!isPessoaJuridica" cols="12" sm="12" md="6" class="py-0">
                            <v-text-field label="Nome" placeholder="Digite o nome do cliente" outlined
                                v-model="dadosCliente.nome" :disabled="loading" :loading="loading"></v-text-field>
                        </v-col>
                        <v-col v-if="isPessoaJuridica" cols="12" sm="12" md="6" class="py-0">
                            <v-text-field label="Nome fantasia" placeholder="Digite o nome fantasia" outlined
                                v-model="dadosCliente.nomeFantasia"></v-text-field>
                        </v-col>
                        <v-col v-if="!isPessoaJuridica" cols="12" sm="12" md="3" class="py-0">
                            <v-text-field v-maska data-maska="##/##/####" label="Data de nascimento"
                                placeholder="Digite a data de nascimento" outlined
                                v-model="dadosCliente.dataNascimento"></v-text-field>
                        </v-col>
                        <v-col v-if="!isPessoaJuridica" cols="12" sm="12" md="2" class="py-0">
                            <v-select v-model="dadosCliente.genero" :items="opGenero" label="Genero" outlined
                                :disabled="loading" :loading="loading"></v-select>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" class="py-0">
                            <v-select v-model="dadosCliente.classificacao" :items="opClassificacao"
                                label="Categoria / Classificação" outlined :disabled="loading"
                                :loading="loading"></v-select>
                            {{ dadosCliente.classificacao }}
                        </v-col>
                        <v-col cols="12" sm="4" md="3" class="py-0">
                            <v-file-input v-model="logo" label="LOGO CLIENTE" class="c-logo-empresa" color="segunda"
                                outlined prepend-icon="" prepend-inner-icon="wallpaper">
                            </v-file-input>
                        </v-col>
                        <v-col cols="12" sm="4" md="3" class="py-0 py-md-1">
                            <v-switch v-model="dadosCliente.situacaoCadastro"
                                :label="`Situação Cadastro - ${dadosCliente.situacaoCadastro ? 'Ativo' : 'Inativo'}`"
                                hide-details>
                            </v-switch>
                        </v-col>
                        <v-col v-if="isPessoaJuridica" cols="1" sm="4" md="1" class="py-0 py-md-1">
                            <v-checkbox v-model="dadosCliente.isento" label="Isento"></v-checkbox>
                        </v-col>

                        <!-- Endereço empresa -->
                        <!-- Ceparador -->
                        <v-col cols="12" sm="12" md="12" class="py-10 pt-11">
                            <v-row>
                                <v-divider class="ma-2 linha-endereco" />
                                <h6 class="text-caption text-uppercase"> Dados de Endereço</h6> <v-divider
                                    class="ma-2 linha-endereco" />
                            </v-row>
                        </v-col>
                        <!-- Fim ceparador -->
                        <v-col cols="12" sm="12" md="3" class="py-0">
                            <v-text-field v-maska data-maska="##.###-###" label="CEP" placeholder="Digite o CEP" outlined
                                v-model="dadosClienteEndereco.cep" append-icon="location_searching"
                                @click:append="consultarCepEmpresa" v-on:keyup.enter="consultarCepEmpresa"
                                :disabled="loading" :loading="loading"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="5" class="py-0">
                            <v-text-field label="Endereço" placeholder="Digite o Endereço" outlined
                                v-model="dadosClienteEndereco.endereco" :disabled="loading"
                                :loading="loading"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="2" class="py-0">
                            <v-text-field label="Número" placeholder="Digite o número" outlined
                                v-model="dadosClienteEndereco.numero"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" class="py-0">
                            <v-text-field label="Bairro" placeholder="Digite o bairro" outlined
                                v-model="dadosClienteEndereco.bairro" :disabled="loading" :loading="loading"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="5" class="py-0">
                            <v-text-field label="Complemento" placeholder="Digite o complemento" outlined
                                v-model="dadosClienteEndereco.complemento"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="2" class="py-0">
                            <v-text-field label="Cidade" placeholder="Digite a cidade" outlined
                                v-model="dadosClienteEndereco.cidade" :disabled="loading" :loading="loading"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="2" class="py-0">
                            <v-select return-object v-model="dadosClienteEndereco.estado" :items="opEstado"
                                :loading="loading" item-value="nome" item-text="nome" label="Estado" outlined>
                            </v-select>

                        </v-col>

                        <!-- Dados de contato da empresa -->
                        <!-- Ceparador -->
                        <v-col cols="12" sm="12" md="12" class="py-10 pt-11">
                            <v-row>
                                <v-divider class="ma-2 linha-endereco" />
                                <h6 class="text-caption text-uppercase"> DADOS PARA CONTATO</h6> <v-divider
                                    class="ma-2 linha-endereco" />
                            </v-row>
                        </v-col>
                        <!-- Fim ceparador -->
                        <v-col cols="12" sm="12" md="3" class="py-0">
                            <v-text-field v-maska data-maska="(##) ####-####" label="Telefone"
                                placeholder="Digite o telefone" outlined
                                v-model="dadosClienteEndereco.telefone"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" class="py-0">
                            <v-text-field v-maska data-maska="(##) # ####-####" label="Celular"
                                placeholder="Digite o celular" outlined
                                v-model="dadosClienteEndereco.celular"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" class="py-0">
                            <v-text-field label="Website" placeholder="Digite o site" outlined
                                v-model="dadosClienteEndereco.website"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" class="py-0">
                            <v-text-field label="E-mail" placeholder="Digite o e-mail" outlined
                                v-model="dadosClienteEndereco.email" persistent-hint
                                hint="Este e-mail irá receber a nota fiscal. Para mais de um e-mail, separe por ponto e vírgula (;)"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" class="py-0 mt-3">
                            <v-textarea filled label="Observações" auto-grow
                                v-model="dadosCliente.observacoes"></v-textarea>
                        </v-col>
                        <!-- BOTÃO CRIAR EMPRESA -->
                        <!-- <v-col cols="12" sm="12" md="12" class="py-4 text-right">
                            <v-btn v-if="dadosCliente.objectId !== null" elevation="10" large right color="primary"
                                class="black--text subtitle-1 font-weight-bold" @click="addCliente()" :loading="loading"
                                :disabled="loading">
                                ATUALIZAR DADOS DA EMPRESA<v-icon class="pl-2" dark color="">mdi-reload</v-icon>
                            </v-btn>
                            <v-btn v-else elevation="10" large right color="primary"
                                class="black--text subtitle-1 font-weight-bold" @click="addCliente()" :loading="loading"
                                :disabled="loading">
                                SALVAR DADOS DA EMPRESA<v-icon class="pl-2" dark
                                    color="">mdi-content-save-cog-outline</v-icon>
                            </v-btn>
                        </v-col> -->
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12">
            <v-card color="secondary">
                <v-card-title>
                    <h3 class="text-h6 text-uppercase font-weight-bold">Configurações</h3>
                    <v-divider class="ml-2"></v-divider>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <!--  CONFIGURAÇÕES -->
                        <v-col cols="12" sm="12" md="3" class="py-0 pt-5 py-md-3">
                            <v-select v-model="configuracoesCliente.listaPreco" :items="opListaDepreco"
                                label="Lista de preço" outlined :disabled="loading" :loading="loading" dense hide-details
                                clearable>
                            </v-select>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" class="py-0 pt-5 py-md-3">
                            <v-select v-model="configuracoesCliente.condicaoPagamento" :items="opCondicaoDePagamento"
                                label="Condição de pagamento" outlined :disabled="loading" :loading="loading" dense
                                hide-details clearable></v-select>
                        </v-col> <v-spacer />
                        <v-col cols="12" sm="12" md="3" class="py-0 pt-5 py-md-3">
                            <v-select v-model="configuracoesCliente.nomeVendedor" :items="opNomeVendedor"
                                label="Nome do Vendedor" outlined :disabled="loading" :loading="loading" dense hide-details
                                clearable></v-select>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" class="py-0 pt-5 py-md-3">
                            <v-select v-model="configuracoesCliente.nomeProfissional" :items="opNomeProfissional"
                                label="Nome do Profissional" outlined :disabled="loading" :loading="loading" dense
                                hide-details clearable></v-select>
                        </v-col>
                    </v-row>
                </v-card-text>

            </v-card>
        </v-col>
        <v-speed-dial fixed v-model="btnAcoes" :bottom="true" :right="true" transition="slide-y-reverse-transition"
            class="ma-0 pa-0">
            <template v-slot:activator>
                <v-btn v-model="btnAcoes" color="primary" dark fab class="mt-n3" :loading="loading" :disabled="loading">
                    <v-icon v-if="dadosCliente.objectId !== null" large color="bg">
                        cloud_sync
                    </v-icon>
                    <v-icon v-else large color="bg">
                        backup
                    </v-icon>
                </v-btn>
            </template>

            <!-- Botão salvar dados cliente ou atualizar -->
            <span v-if="dadosCliente.objectId !== null" class="c-dial-text primary--text">ATUALIZAR</span>
            <span v-else class="c-dial-text primary--text mt-n2">SALVAR</span>
            <v-btn fab @click="addCliente()">
                <v-icon v-if="dadosCliente.objectId !== null">mdi-reload</v-icon>
                <v-icon v-else>mdi-content-save-cog-outline</v-icon>
            </v-btn>

            <!-- Botão voltar -->
            <span class="c-dial-text primary--text mt-n2">VOLTAR</span>
            <v-btn fab to="/Dashboard/ListarClientes/">
                <v-icon class="u-rotacao">shortcut</v-icon>
            </v-btn>
        </v-speed-dial>
    </v-row>
</template>
  
<script>
import barramento from "@/barramento"
import axios from "axios";
import { urlApiViaCep, urlApiFiles, UF } from "@/global";
export default {
    name: "Empresa-screen",
    props: {},
    components: {},
    data: () => ({
        btnAcoes: true,
        logo: null,
        logoBD: {},
        isPessoaJuridica: true,
        loading: false,
        dadosCliente: {
            objectId: null,
            situacaoCadastro: false
        },
        dadosClienteEndereco: {
            objectId: null
        },
        dadosContabilidade: {},
        dadosContabilidadeEndereco: {},
        opTipoPessoa: ['Pessoa Jurídica', 'Pessoa Física'],
        opClassificacao: ['CLIENTE BOM PAGADOR', 'CLIENTE COM CONTA ATRASADA', 'CLIENTE EM DIA', 'CLIENTE EM FASE DE AVALIAÇÃO'],
        opTipoCadastro: ['Cliente', 'Fornecedor', 'Ambos'],
        opGenero: ['Mascolino', 'Feminino'],
        configuracoesCliente: {},
        opListaDepreco: [],
        opCondicaoDePagamento: [],
        opNomeVendedor: [],
        opNomeProfissional: [],
        opEstado: UF
    }),
    computed: {
    },
    watch: {
        logo(logoNova, logoAntiga) {
            if (logoNova == null && !('name' in this.logoBD)) return

            if (logoNova == null && 'name' in this.logoBD) {
                this.atualizarLogo(this.logoBD.caminho, 'atualizarArquivo')
                return
            }
            if ('name' in logoNova && logoAntiga == null) {
                this.atualizarLogo(logoNova, 'inserirArquivo')
            }
        },
    },
    methods: {

        identificarPessoa(evento) {
            this.dadosCliente.tipoPessoa = evento
            if (this.dadosCliente.tipoPessoa == 'Pessoa Física') {
                this.isPessoaJuridica = false
            } else {
                this.isPessoaJuridica = true
            }
        },
        carregarConfiguracaoCliente() {
            axios.post('/configuracao-cliente').then(res => {
                this.opListaDepreco = res.data.result.listaPreco
                this.opCondicaoDePagamento = res.data.result.condicaoPagamento
                this.opNomeVendedor = res.data.result.nomeVendedor
                this.opNomeProfissional = res.data.result.nomeProfissional

            }).catch(erro => {
                this.$toasted.global.defaultError(erro.response.data.message);
            })
        },
        atualizarLogo(logo, acao) {
            let logoAdd = []

            logoAdd.push(logo)

            let formData = new FormData();
            logoAdd.filter((ele) => {
                formData.append('arquivo', ele);
                formData.append('acao', acao);
                formData.append('classBd', 'Cliente');
                formData.append('contexto', 'Logo Cliente');
            });
            axios
                .post(`${urlApiFiles}/UpArquivo`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    if (res.data.arquivoDeletado) {
                        this.logoBD = {}
                        this.$toasted.global.defaultSuccess(false);
                        return
                    }
                    this.logoBD = { ...res.data[0] };
                    this.$toasted.global.defaultSuccess('Dados da logo armazenado!');
                })
                .catch(err => {
                    this.$toasted.global.defaultError(err.response.data.message);
                });
        },
        carregarCliente(id) {
            if(id == 'add') return;

            barramento.enviarProgresso(true)

            axios.post('/carregar-cliente', { idCliente: id }).then(res => {

                this.dadosClienteEndereco = res.data.result.endereco
                delete res.data.result.endereco
                this.dadosCliente = res.data.result

                this.$toasted.global.defaultSuccess('Dados do cliente carregado');
                barramento.enviarProgresso(false)
                
            }).catch(err => {
                this.$toasted.global.defaultError(err.response.data.message);
                barramento.enviarProgresso(false)
            })
        },
        consultarCNPJ() {
            this.loading = true
            axios
                .get('#')
                .then(res => {
                    this.dadosCliente.razaoSocial = res.data.razao_social
                    this.dadosCliente.tipoPessoa = "Pessoa Jurídica"
                    this.dadosClienteEndereco.cep = res.data.endereco.cep
                    this.dadosClienteEndereco.bairro = res.data.endereco.bairro
                    this.dadosClienteEndereco.endereco = res.data.endereco.logradouro
                    this.dadosClienteEndereco.numero = res.data.endereco.numero
                    this.dadosClienteEndereco.estado = this.opEstado.find(estado => estado.sigla == res.data.endereco.uf)
                    this.dadosClienteEndereco.cidade = res.data.endereco.nome_municipio
                    this.$toasted.global.defaultSuccess('CNPJ encontrado!');
                    this.loading = false

                }).catch(err => {
                    this.$toasted.global.defaultError(err.response.data.message);
                    this.loading = false
                })
        },
        addCliente() {
            this.loading = true

            if (this.isPessoaJuridica) {
                this.dadosCliente.cpf = ''
                this.dadosCliente.nome = ''
                this.dadosCliente.dataNascimento = ''
                this.dadosCliente.genero = ''
                this.dadosCliente.nomeParaTabela = this.dadosCliente.razaoSocial
                this.dadosCliente.documento = this.dadosCliente.cnpj
            } else {
                this.dadosCliente.razaoSocial = ''
                this.dadosCliente.cnpj = ''
                this.dadosCliente.nomeFantasia = ''
                this.dadosCliente.nomeParaTabela = this.dadosCliente.nome
                this.dadosCliente.documento = this.dadosCliente.cpf
            }

            const dataCliente =  Object.assign({}, this.dadosCliente)
            dataCliente.dadosClienteEndereco = this.dadosClienteEndereco
            dataCliente.foto = this.logoBD
            dataCliente.configuracoes = this.configuracoesCliente

            axios
                .post('/cadastrar-cliente', dataCliente)
                .then(res => {
                    this.dadosCliente.objectId = res.data.result.idCliente
                    this.dadosClienteEndereco.objectId = res.data.result.idEndereco
                    this.dadosClienteEndereco.situacaoCadastro = res.data.result.situacaoCadastro
                    this.loading = false
                    this.$toasted.global.defaultSuccess(false);
                }).catch(err => {
                    this.loading = false
                    this.$toasted.global.defaultError(err.response.data.error);
                })
        },
        parseCepECnpj(cep) {
            return cep.replace(/[^0-9]+|\s+/g, "");
        },
        consultarCepEmpresa() {
            this.loading = true
            const cep = this.parseCepECnpj(this.dadosClienteEndereco.cep)

            axios.get(urlApiViaCep(cep)).then(res => {
                this.dadosClienteEndereco.endereco = res.data.logradouro
                this.dadosClienteEndereco.bairro = res.data.bairro
                this.dadosClienteEndereco.cidade = res.data.localidade
                this.dadosClienteEndereco.estado = this.opEstado.find(estado => estado.sigla == res.data.uf)
                if (res.data.erro) {
                    this.$toasted.global.defaultError('Nenhum dado para o CEP informado');
                }
                this.loading = false
            }).catch(err => {
                err.msg = 'CEP Inválido!'
                this.$toasted.global.defaultError(err.msg);
                this.loading = false
            })
        },
        consultarCepContador() {
            this.loading = true
            const cep = this.parseCepECnpj(this.dadosContabilidadeEndereco.cep)
            axios.get(urlApiViaCep(cep)).then(res => {
                this.dadosContabilidadeEndereco.endereco = res.data.logradouro
                this.dadosContabilidadeEndereco.bairro = res.data.bairro
                this.dadosContabilidadeEndereco.cidade = res.data.localidade
                this.dadosContabilidadeEndereco.estado = this.opEstado.find(estado => estado.sigla == res.data.uf)
                if (res.data.erro) {
                    this.$toasted.global.defaultError('Nenhum dado para o CEP informado');
                }
                this.loading = false
            }).catch(err => {
                err.msg = 'CEP Inválido!'
                this.$toasted.global.defaultError(err.msg);
                this.loading = false
            })
        }
    },
    created() {
        if ('id' in this.$route.params) {
            this.carregarCliente(this.$route.params.id)
            this.carregarConfiguracaoCliente()
        }
    },
    beforeMount() { },
    mounted() { }
};
</script>
  
<style>
.c-dial-text {
    display: block;
    height: 30px;
    padding-top: 9px;
    width: 56px;
    font-size: 10px;
    line-height: 10px;
    font-weight: 500;
}

.c-logo-empresa .v-label {
    font-size: 11px;
}

.linha-endereco {
    border-style: dashed;
}

#c-list-empresas .v-item-group {
    display: flex;
}
</style>