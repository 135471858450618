<!-- eslint-disable -->
<template>
    <v-card>
        <v-card-title>Categorias | <v-chip outlined :color="isEditarCategoria ? 'error' : 'primaryMn'"> <v-switch
                    color="error" class="mt-0 pt-0" hide-details dense v-model="isEditarCategoria">
                    <template v-slot:label>
                        <span :class="{ 'c-text--error': isEditarCategoria }"
                            class="text-caption primaaryMn--text text-uppercase">
                            REMOVER CATEGORIAS
                        </span>
                    </template>
                </v-switch>
            </v-chip></v-card-title>
        <v-card-text>
            <v-data-table :headers="headers" :items="categories" :items-per-page="-1" class="elevation-1">
                <template v-slot:top>
                    <v-toolbar extended flat class="pt-7">
                        <v-text-field hide-details outlined v-model="newCategoryName"
                            label="Nome da Categoria"></v-text-field>
                        <v-select item-text="value" item-value="value" hide-details outlined v-model="selectedType"
                            :items="categoryTypes" label="Tipo" class="mx-10">
                            <template v-slot:selection="{ item }">
                                {{ item.value }}
                            </template>
                            <template v-slot:item="{ item, on, attrs }">
                                <v-list-item v-on="on" v-bind="attrs" class="text-left" two-line>
                                    <v-list-item-content>
                                        <v-list-item-title>--{{ item.value }}</v-list-item-title>
                                        <v-list-item-subtitle v-for="item in item.examples" :key="item" class="pl-3">{{ item
                                        }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-select>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-select v-bind="attrs" v-on="on" return-object hide-details outlined
                                    v-model="selectedParent" :items="parentCategoryItems" label="Categoria Ascendente"
                                    class="mx-10" item-text="name">
                                </v-select>
                            </template>
                            <span>Selecione 'Ascendente' caso sua intenção seja criar uma categoria com
                                subcategorias.</span>
                        </v-tooltip>
                        <v-spacer></v-spacer>
                        <v-btn outlined color="primary" @click="saveCategory" class="mx-5">
                            {{ editingIndex !== null ? 'Salvar Edição' : 'Salvar Categoria' }}
                        </v-btn>
                        <v-btn outlined @click="cancelEdit" v-if="editingIndex !== null">Cancelar</v-btn>
                    </v-toolbar>
                </template>
                <template v-slot:item.action="{ item }">
                    <v-btn @click="editCategory(item)" icon>
                        <v-icon color="primary">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn v-if="isEditarCategoria" @click="removeCategory(item)" icon>
                        <v-icon color="primary">mdi-delete</v-icon>
                    </v-btn>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>
  
<script>
import barramento from "@/barramento"
import axios from 'axios';
// Url da API servidor banco
import { urlApiBd } from "@/global";
export default {
    name: "Categoria-screen",
    data() {
        return {
            isEditarCategoria: false,
            headers: [
                { text: 'Nome da Categoria', value: 'name' },
                { text: 'Tipo', value: 'type' },
                { text: 'Categoria Pai', value: 'parent' },
                { text: 'Ações', value: 'action', sortable: false },
            ],
            categories: [],
            newCategoryName: '',
            selectedType: null,
            selectedParent: {
                name: null,
            },
            /* categoryTypes: ['Marca', 'Funcionalidade/Tipo de Produto', 'Ambiente/Divisão', 'Por Estilo/Design', 'Outro'], */
            categoryTypes: [
                { value: 'Marca', examples: [] },
                { value: 'Funcionalidade/Tipo de Produto', examples: [] },
                {
                    value: 'Ambiente/Divisão', examples: ['Produtos para áreas externas', 'Produtos para áreas internas', 'Produtos para escritórios', 'Produtos para residências']
                },
                {
                    value: 'Estilo/Design', examples: ['Produtos discretos/invisíveis', 'Produtos estilizados/ornamentais', 'Cor']
                },
                { value: 'Outro', examples: [] },

            ],
            editingIndex: null, // Índice da categoria sendo editada, inicialmente nulo
        };
    },
    computed: {
        parentCategoryItems() {
            // Adicionar a opção "Ascendente" à lista de categorias pai
            // Utilizar map para extrair apenas o atributo "name" de cada objeto
            const namesOnly = this.categories
                .filter(category => category.name !== this.newCategoryName)
                .map(category => ({ name: category.name }));

            return [{ name: 'Ascendente' }, ...namesOnly];
        },
    },
    methods: {
        removeCategory(item) {
            axios
                .post(`${urlApiBd}/deletar-categoria`, item)
                .then((res) => {
                    this.editingIndex = this.categories.indexOf(item);

                    // Verifica se o índice é válido
                    if (this.editingIndex !== -1) {
                        this.categories.splice(this.editingIndex, 1);
                    }

                    this.$toasted.global.defaultSuccess(res.data.result);
                    this.limparCamposCategoria();
                })
                .catch(err => {
                    this.$toasted.global.defaultError(err.response.data.error);
                });
        },
        verificarSeCategoriaExiste(name) {
            const cleanName = name.trim().toLowerCase();
            return this.categories.some(category => category.name.trim().toLowerCase() === cleanName);
        },
        limparCamposCategoria() {
            // Limpar os campos após salvar ou editar
            this.newCategoryName = '';
            this.selectedType = null;
            this.selectedParent = {
                name: null
            }
            this.editingIndex = null;
        },
        loadCategories() {
            barramento.enviarProgresso(true)
            axios
                .post(`${urlApiBd}/listar-categorias`)
                .then(response => {
                    // Certifique-se de que response.data é uma matriz
                    this.categories = Array.isArray(response.data.result) ? response.data.result : [];
                    barramento.enviarProgresso(false);
                })
                .catch(err => {
                    this.$toasted.global.defaultError(err.response.data.error);
                    barramento.enviarProgresso(false);
                });
        },
        saveCategory() {
            if (this.editingIndex !== null) {
                // Editar categoria existente
                this.editExistingCategory();
            }
            else if (this.verificarSeCategoriaExiste(this.newCategoryName)) {
                this.$toasted.global.defaultError('Categoria já existe, tente outro nome.');
                return
            }
            else {
                // Adicionar nova categoria
                this.addNewCategory();
            }

        },
        addNewCategory() {
            const newCategory = {}

            newCategory.name = this.newCategoryName,
                newCategory.type = this.selectedType,
                newCategory.parent = this.selectedParent.name,

                this.criarCategoria(newCategory, true);
        },
        criarCategoria(categoria, IsNovaCategoria) {
            axios
                .post(`${urlApiBd}/cadastrar-categoria`, categoria)
                .then((res) => {
                    if (IsNovaCategoria) this.categories.push(categoria);
                    this.$toasted.global.defaultSuccess(`Categoria ${res.data.result.name} cadastrada!`);
                    this.limparCamposCategoria();
                })
                .catch(err => {
                    this.$toasted.global.defaultError(err.response.data.error);
                });
        },
        editExistingCategory() {
            if (this.editingIndex !== null && this.editingIndex < this.categories.length) {
                // Editar categoria existente
                this.categories[this.editingIndex].name = this.newCategoryName;
                this.categories[this.editingIndex].type = this.selectedType;
                this.categories[this.editingIndex].parent = this.selectedParent.name
                // Alterar a categoria no banco de dados
                this.criarCategoria(this.categories[this.editingIndex], false);
            }
        },

        editCategory(item) {
            // Editar uma categoria existente
            this.newCategoryName = item.name;
            this.selectedType = item.type;
            this.selectedParent = {
                name: item.parent
            }; // Definir como 'Ascendente' se não houver categoria pai
            this.editingIndex = this.categories.indexOf(item);

        },
        cancelEdit() {
            // Cancelar a edição, limpar os campos e resetar a variável editingIndex
            this.limparCamposCategoria();
        },
    },
    mounted() {
        // Carregar categorias ao montar o componente
        this.loadCategories();
    },
};
</script>
  
<style scoped>
/* Estilos do componente, se necessário */
</style>
  
  
  