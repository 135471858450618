<template>
  <v-row class="ma-0 pa-0">
    <!-- Formúlario Cliente -->
    <v-col v-if="'id' in $route.params" cols="12" class="ma-0 pa-0">
      <v-slide-y-transition leave-absolute>
        <router-view></router-view>
      </v-slide-y-transition>
    </v-col>
    <!-- Lista de Clientes -->
    <v-col v-else cols="12" class="ma-0 pa-0">
      <v-slide-y-transition hide-on-leave appear>
        <v-data-table :headers="headers" :items="clientes" :search="pesquisaCliente" sort-by="nomeParaTabela"
          class="ma-0 pa-0">
          <template v-slot:top>
            <v-toolbar flat>

              <v-btn fab small class="mr-4" to="/Dashboard/ListarClientes/Cliente/add">
                <v-icon color="primary">domain_add</v-icon>
              </v-btn>

              <v-divider inset vertical />
              <v-spacer />

              <v-text-field v-model="pesquisaCliente" append-icon="mdi-magnify" label="Pesquisar Cliente" single-line
                hide-details />

            </v-toolbar>
          </template>

          <!-- Situação cadastro -->
          <template v-slot:[`item.situacaoCadastro`]="{ value }">
            <span v-if="value">Ativo</span>
            <span v-else>Inativo</span>
          </template>

          <!-- Tipo cadastro -->
          <template v-slot:[`item.tipoCadastro`]="{ value }">
            <span v-if="value == 'Ambos'">Cliente e Fornecedor</span>
            <span v-else>{{ value }}</span>
          </template>

          <!-- Ações -->
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon large :to="`/Dashboard/ListarClientes/Cliente/${item.objectId}`">
              <v-icon large>
                mdi-folder-edit
              </v-icon>
            </v-btn>
            <!--  <v-icon small>
        mdi-delete
      </v-icon> -->
          </template>

          <!-- Quando não houver dados na tabela -->

          <template v-slot:no-data>
            <v-btn @click="listarClientes">
              BUSCAR CLIENTES
            </v-btn>
          </template>
        </v-data-table>
      </v-slide-y-transition>
    </v-col>
  </v-row>
</template>
  
<script>
import barramento from "@/barramento"
import axios from "axios";
export default {
  name: "ListarClientes",
  data: () => ({
    pesquisaCliente: '',
    headers: [
      { text: 'ID', align: 'start', value: 'objectId', class: 'd-none', cellClass: 'd-none' },
      { text: 'Tipo', align: 'start', value: 'tipoCadastro' },
      { text: 'Nome', align: 'start', value: 'nomeParaTabela' },
      { text: 'CNPJ / CPF', value: 'documento' },
      { text: 'Contato / WhatsApp', value: 'endereco.celular' },
      { text: 'Situação', value: 'situacaoCadastro' },
      { text: 'Ações', value: 'actions', sortable: false },
    ],
    clientes: [],
  }),

  computed: {},

  watch: {},

  methods: {
    listarClientes() {
      barramento.enviarProgresso(true)
      axios
        .post('listar-clientes').then(res => {
          this.clientes = res.data.result
          console.log('API E-commerce AC TURBO - CLIENTES', this.clientes)
          barramento.enviarProgresso(false)
          
        }).catch(res => {
          this.$toasted.global.defaultError(res.msg);
          barramento.enviarProgresso(false)
        })
    },
  },
  created() {
    this.listarClientes()
  },
  /*  beforeRouteUpdate(to, from, next) {
     if ('id' in to.params) {
       this.dialogTelaCliente = true
     } else {
       this.dialogTelaCliente = false
     }
     console.log('to', to, 'from', from)
     next()
   } */

   beforeRouteUpdate(to, from, next) {
        if ('id' in from.params) {
          this.listarClientes()
        }
       /*  console.log('to', to, 'from', from) */
        next()
    }
};
</script>
  
<style></style>