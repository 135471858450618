<!-- eslint-disable -->
<template>
    <v-card>
        <v-card-title>Lista de Pedidos</v-card-title>
        <v-card-text>
            <v-data-table :headers="headers" :items="orders" :items-per-page="5" class="elevation-1">
                <template v-slot:top>
                    <v-toolbar extended flat class="pt-7">
                        <v-text-field hide-details outlined v-model="newOrderName" label="Nome do Cliente"></v-text-field>
                        <v-select hide-details outlined v-model="selectedStatus" :items="orderStatusOptions" label="Status"
                            class="mx-10"></v-select>
                        <v-spacer></v-spacer>
                        <v-btn outlined color="primary" @click="createOrder" class="mx-5">
                            {{ editingIndex !== null ? 'Atualizar Pedido' : 'Criar Pedido' }}
                        </v-btn>
                        <v-btn outlined @click="cancelEdit" v-if="editingIndex !== null">Cancelar</v-btn>
                    </v-toolbar>
                </template>
                <template v-slot:item.action="{ item }">
                    <v-btn @click="viewCustomerDetails(item)">
                        DADOS DO CLIENTE
                        <v-icon color="info" right>mdi-eye</v-icon>
                    </v-btn>
                    <v-btn @click="editOrder(item)" icon>
                        <v-icon color="primary">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn @click="removeOrder(item)" icon>
                        <v-icon color="error">mdi-delete</v-icon>
                    </v-btn>
                </template>
                <template v-slot:item.status="{ item }">
                    <v-chip :color="statusColor(item.status)" dark>{{ item.status }}</v-chip>
                </template>
            </v-data-table>

            <!-- Modal de Detalhes do Cliente -->
            <v-dialog v-model="viewDetailsDialog" max-width="600">
                <v-card v-if="selectedOrder">
                    <v-card-title class="headline">Dados do cliente: {{ selectedOrder.clientName }}</v-card-title>
                    <v-card-text>
                        <div>
                            <strong>Dados para envio:</strong> DADOS<br /> <v-btn>IMPRIMIR</v-btn>
                        </div>

                        <div>
                            <strong>Status:</strong> {{ selectedOrder.status }}
                        </div>
                        <!-- Adicione mais detalhes do cliente conforme necessário -->
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" @click="viewDetailsDialog = false">Fechar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card-text>
    </v-card>
</template>
  
<script>
import axios from 'axios';

const urlApi = 'sua_url_api'; // Substitua pela sua URL de API
const configK = {}; // Substitua pelo cabeçalho necessário

export default {
    data() {
        return {
            headers: [
                { text: 'Cliente', value: 'clientName' },
                { text: 'Status', value: 'status' },
                { text: 'Ações', value: 'action', sortable: false },
            ],
            orders: [
                { clientName: 'João Silva', status: 'Em Processo' },
                { clientName: 'Maria Oliveira', status: 'Entregue' },
                { clientName: 'Carlos Pereira', status: 'Cancelado' },
                // Adicione mais pedidos conforme necessário
            ],
            newOrderName: '',
            selectedStatus: 'Em Processo',
            editingIndex: null, // Índice do pedido sendo editado, inicialmente nulo
            viewDetailsDialog: false,
            selectedOrder: null,
        };
    },
    computed: {
        orderStatusOptions() {
            return ['Em Processo', 'Entregue', 'Cancelado'];
        },
    },
    methods: {
        statusColor(status) {
            return status === 'Em Processo' ? 'warning' : status === 'Entregue' ? 'success' : 'error';
        },
        createOrder() {
            if (this.editingIndex !== null) {
                // Atualizar pedido existente
                this.updateExistingOrder();
            } else {
                // Criar novo pedido
                this.addNewOrder();
            }

            // Limpar os campos após salvar ou editar
            this.newOrderName = '';
            this.selectedStatus = 'Em Processo';
            this.editingIndex = null;
        },
        addNewOrder() {
            const newOrder = {
                clientName: this.newOrderName,
                status: this.selectedStatus,
            };

            this.orders.push(newOrder);
            this.createOrderOnApi(newOrder);
        },
        updateExistingOrder() {
            if (this.editingIndex !== null && this.editingIndex < this.orders.length) {
                // Atualizar pedido existente
                this.orders[this.editingIndex].clientName = this.newOrderName;
                this.orders[this.editingIndex].status = this.selectedStatus;
                // Lógica para atualizar no banco de dados, se necessário
            }
        },
        createOrderOnApi(order) {
            axios
                .post(`${urlApi}/create-order`, order, {
                    headers: configK,
                })
                .then(() => {
                    this.$toasted.global.defaultSuccess({
                        msg: 'Pedido criado com sucesso!',
                    });
                })
                .catch((error) => {
                    // Lógica para exibir erro, se necessário
                    console.error(error);
                });
        },
        removeOrder(item) {
            // Implemente a lógica para remover um pedido do banco de dados
            const index = this.orders.indexOf(item);
            if (index !== -1) {
                this.orders.splice(index, 1);
                // Chame a função para remover do banco de dados, se necessário
            }
        },
        editOrder(item) {
            // Editar um pedido existente
            this.newOrderName = item.clientName;
            this.selectedStatus = item.status;
            this.editingIndex = this.orders.indexOf(item);
        },
        viewCustomerDetails(order) {
            // Abrir o modal de detalhes do cliente
            this.selectedOrder = order;
            this.viewDetailsDialog = true;
        },
        cancelEdit() {
            // Cancelar a edição, limpar os campos e resetar a variável editingIndex
            this.newOrderName = '';
            this.selectedStatus = 'Em Processo';
            this.editingIndex = null;
        },
    },
};
</script>
  
<style scoped>
/* Estilos do componente, se necessário */
</style>
  