<template>
    <v-row justify="center" align-content="center" align="center">
        <v-col cols="12" sm="6">
            <v-slide-y-transition appear>
                <v-row class="text-center">
                    <v-col cols="12">
                        <v-img v-if="$vuetify.theme.dark" :src="require('../assets/favicon-ds.png')" class="my-4" contain
                            height="90" />
                        <v-img v-if="!$vuetify.theme.dark" :src="require('../assets/favicon-ds.png')" class="my-4"
                            contain height="90" />
                    </v-col>
                </v-row>
            </v-slide-y-transition>
            <v-slide-y-reverse-transition appear>
                <v-card color="secondary" class="mx-auto py-9 pb-16" max-width="344" flat elevation="20" shaped>
                    <v-row>
                        <v-col cols="12" sm="12" class="py-0 px-8">
                            <v-text-field color="segunda" v-model="user.username" type="email" name="email"
                                append-icon="email" :rules="[rules.required]" label="Usuário"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" class="py-0 px-8">
                            <v-text-field color="segunda" :append-icon="trocarIconeLogin ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[rules.required, rules.min]" :type="trocarIconeLogin ? 'text' : 'password'"
                                name="password" label="Senha" class="input-group--focused"
                                @click:append="trocarIconeLogin = !trocarIconeLogin" v-on:keyup.enter="signin"
                                v-model="user.password">
                            </v-text-field>
                            <v-btn absolute left color="primary" class="white--text ml-1" @click="signin"
                                v-on:dblclick.prevent>ENTRAR</v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-slide-y-reverse-transition>
        </v-col>
    </v-row>
</template>
  
<script>
import axios from "axios";
import { userKey, urlApiBd } from "@/global";

export default {
    name: "Login-screen",
    props: {},
    components: {},
    data: () => ({
        user: {},
        trocarIconeLogin: false,
        rules: {
            required: value => !!value || "Campo obrigatório.",
            min: v => {
                if (v == undefined) {
                    return "Digite uma senha";
                } else {
                    return v.length >= 8 || "Min de 8 caracteres";
                }
            },
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || "Email inválido.";
            }
        }
    }),
    methods: {
        signin() {
            axios
            .post(`${urlApiBd}/login`, this.user)
                .then(res => {
                    console.log('res', res.data.result)
                    sessionStorage.setItem('keyVhsys__aviz__user', JSON.stringify(res.data.result.cofingHeadersVhsys));
                    delete res.data.result.cofingHeadersVhsys
                    localStorage.setItem(userKey, JSON.stringify(res.data.result));
                    this.$toasted.global.defaultSuccess(false);
                    this.$router.push('/Dashboard')
                }).catch((err) => {
                    localStorage.removeItem(userKey);
                    err.msg = 'Credenciais inválidas'
                    this.$toasted.global.defaultError(err.msg);
                });
        },
    },
};
</script>
  
<style></style>